import React, { useMemo, useState } from 'react';

import BriefHeader from 'features/briefs/BriefHeader/BriefHeader';
import BriefStatus from 'features/briefs/BriefStatus';
import CreatorBriefTabs from 'features/briefs/components/organisms/CreatorBriefTabs';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import BackButton from 'shared/components/atoms/BackButton';
import ResponsiveDisplay from 'shared/components/atoms/ResponsiveDisplay';
import { Box, Flex } from 'shared/components/display';

import { BRIEF_COPY } from 'shared/config/copy';
import { UserType } from 'shared/typings/user/enums';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

interface Props {
  briefId: number;
}

const CreatorIndividualBriefPage: React.FC<Props> = ({ briefId }) => {
  const { brief, loading } = useBriefs(briefId);
  const [briefTabHeight, setBriefTabHeight] = useState(0);

  const briefPagePath = useMemo(
    () =>
      LinkCreator.createLink({
        userType: UserType.CREATOR,
        routeKey: [UserBriefStatus.ACTIVE, UserBriefStatus.DRAFTED, UserBriefStatus.REVISION].includes(
          brief?.briefCreatorStatus,
        )
          ? 'CREATOR_SUBMISSIONS'
          : 'BRIEFS_BOARD',
      }),
    [brief?.briefCreatorStatus],
  );

  if (loading || !brief) {
    return <CenteredSpinnerContainer />;
  }

  return (
    <Box css={styles(briefTabHeight)}>
      <Flex className="individual-brief">
        <Flex className="individual-brief__top-bar">
          <BackButton to={briefPagePath}>{BRIEF_COPY.BUTTON_BACK_TO_BRIEFS}</BackButton>
          <ResponsiveDisplay display="mobile">
            <BriefStatus
              briefCreatorStatus={brief.briefCreatorStatus}
              full={brief.full}
              inviteOnly={brief.inviteOnly}
            />
          </ResponsiveDisplay>
          <BriefHeader currentBrief={brief} />
        </Flex>
        <Box className="individual-brief__body">
          <CreatorBriefTabs brief={brief} loading={loading} setHeight={setBriefTabHeight} />
        </Box>
      </Flex>
    </Box>
  );
};

export default CreatorIndividualBriefPage;
