import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export const BASE_CLASS = 'split-btn';

const MINI_HEIGHT = '30px';

export const getSplitButtonStyles = (width) =>
  css({
    height: 'fit-content',
    width,
    '.split-btn': {
      '&__button': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(${width} - 46px)`,
        minWidth: '44px',
        marginRight: theme.space[1],
      },
      '&__button--borderless': {
        border: 'none',
        // Parent styles are overriding the padding, so we need to use !important
        paddingRight: '2px !important',
      },

      '&__button--mini': {
        height: MINI_HEIGHT,
        width: `calc(${width} - 32px)`,
      },
      '&__button--flat.split-btn__button--bottom-end, &__button--flat.split-btn__button--bottom-start': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '&__button--flat.split-btn__button--top-end, &__button--flat.split-btn__button--top-start': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
      },
      '&__dropdown-button': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        minWidth: 'unset',
        height: 'unset',
      },
      '&__dropdown-button--default': {
        width: '44px',
      },
      '&__dropdown-button--mini': {
        height: MINI_HEIGHT,
        width: '30px',
      },
      '&__dropdown-button--borderless': {
        border: 'none',
      },
    },
  });
