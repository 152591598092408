import { FormikErrors } from 'formik';
import { at } from 'lodash';

// For array formatted data, if the error isn't the first element, the error array will be the same length as the data but with empty values where the data is correct
export const getFirstArrayError = (errors: any[]): { value: any; index: number } => {
  return errors.reduce(
    (obj, err, index) => {
      if (obj.value) return obj;
      return {
        value: err,
        index,
      };
    },
    { value: '', index: 0 },
  );
};

// Determine if fields in field array both has an error and has been touched
export const arrayTabHasError = (
  errors: Record<string, any>[] | string[],
  touched: (Record<string, any> | boolean)[],
): boolean[] => {
  return errors.map((error, i) => {
    if (!error) return false;
    if (typeof error === 'string') {
      return Boolean(touched[i]);
    }
    return Object.keys(error).reduce((hasError, key) => {
      if (hasError) return hasError;
      return Boolean(error[key]) && touched?.[i]?.[key];
    }, false);
  });
};

// Parse errors for specific fieldname, accounting for field arrays and fields with array formatted data
export const getFirstErrorFieldname = <Values = any>(errors: FormikErrors<Values>, fieldName: string = ''): string => {
  if (!errors) return fieldName;

  if (typeof errors === 'string') return fieldName + errors;
  else if (Array.isArray(errors)) {
    const { value: firstError, index } = getFirstArrayError(errors);
    if (typeof firstError === 'string') return fieldName;
    return getFirstErrorFieldname(firstError, `${fieldName}[${index}].`);
  } else {
    const [firstErrorField] = Object.keys(errors);
    const firstError = errors[firstErrorField];
    if (typeof firstError === 'string') return fieldName + firstErrorField;
    return getFirstErrorFieldname(firstError, fieldName + firstErrorField);
  }
};

export const getAllErrorFieldnames = <Values = any>(errors: FormikErrors<Values>): string[] => {
  const errorKeys = Object.keys(errors);
  const fieldnames: string[] = [];
  while (errorKeys.length > 0) {
    const name = errorKeys.pop();
    const error = name ? at(errors, [name])[0] : undefined;
    if (name && typeof error === 'string') {
      fieldnames.push(name);
    } else if (name && Array.isArray(error)) {
      error.forEach((row, i) => {
        const nestedNames = Object.keys(row);
        errorKeys.push(...nestedNames.map((innerName) => `${name}[${i}].${innerName}`));
      });
    }
  }
  return fieldnames;
};
