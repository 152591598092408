import React, { useEffect } from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import { type Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';

import DraggableItem from './DraggableItem';
import { isItemInList } from './util';
import { ItemData } from './types';
import styles from './styles';

// unique identifier for specific list since drop targets can be nested
const instanceId = Symbol('list');

interface Props {
  children: React.ReactElement<{ children: React.ReactElement | React.ReactElement[]; 'data-id': number | string }>[];
  classNames?: {
    container?: string;
    item?: string;
    itemDragging?: string;
  };
  reorderDroppedItem: (startIndex: number, finishIndex: number) => void;
  dropIndicatorOffset?: string;
  endDropIndicatorOffset?: string;
  axis?: 'vertical' | 'horizontal';
}

const DraggableList: React.FC<Props> = ({
  children,
  classNames = {},
  reorderDroppedItem,
  dropIndicatorOffset = '-3px',
  endDropIndicatorOffset = '',
  axis = 'horizontal',
}) => {
  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isItemInList(source.data, instanceId);
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        if (isItemInList(source.data, instanceId) && isItemInList(target.data, instanceId)) {
          const sourceData = source.data as ItemData;
          const targetData = target.data as ItemData;
          // TODO: if not working see if index on items need to be updated
          const indexOfTarget = targetData.index;
          if (indexOfTarget < 0) {
            return;
          }

          const closestEdgeOfTarget = extractClosestEdge(targetData);

          const startIndex = sourceData.index;

          const finishIndex = getReorderDestinationIndex({
            startIndex,
            closestEdgeOfTarget,
            indexOfTarget,
            axis,
          });

          reorderDroppedItem(startIndex, finishIndex);
        }
      },
    });
  }, [instanceId, reorderDroppedItem]);

  return (
    <Box
      css={styles(dropIndicatorOffset, endDropIndicatorOffset)}
      className={cs('draggable-list', classNames.container)}
    >
      {React.Children.map(children, (Child, idx) => {
        return (
          <DraggableItem
            id={Child.props['data-id']}
            disabled={Child.props['data-dragging-disabled']}
            index={idx}
            key={idx}
            className={classNames.item}
            draggingClassName={classNames.itemDragging}
            instanceId={instanceId}
            axis={axis}
          >
            {React.cloneElement(Child, {
              key: idx,
            })}
          </DraggableItem>
        );
      })}
    </Box>
  );
};

export default DraggableList;
