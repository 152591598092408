import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '&.text-input': {
    position: 'relative',
  },
  '.text-input': {
    '&__characters-remaining': { margin: 0, visibility: 'hidden', height: 0 },
    '&__characters-remaining--active': { visibility: 'initial', height: 'auto' },
    '&__characters-remaining--max': { color: COLORS.RED500 },
  },
  input: {
    '&::placeholder': {
      color: COLORS.NEUTRAL700,
    },
  },
});
