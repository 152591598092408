import React, { useMemo } from 'react';
import cs from 'classnames';
import { useFormikContext } from 'formik';

import Tag from 'shared/components/atoms/Tag';
import { Button, Flex } from 'shared/components/display';
import DraggableList from 'shared/components/molecules/DraggableList';

import { BUTTON_VARIANTS } from 'shared/styles/button';
import { arrayTabHasError } from 'shared/utilities/formikUtility';

import styles, { tabCss } from './styles';

interface Props {
  onRemove: (idx: number) => void;
  canRemove?: boolean;
  canAdd?: boolean;
  onAdd?: () => void;
  addText?: string;
  name: string;
  defaultTabText?: string;
  allowOnlyTabToBeDeleted?: boolean;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  selectedTab: number;
  move: (from: number, to: number) => void;
}
const FieldArrayTabBar: React.FC<Props> = ({
  name,
  onRemove,
  canRemove,
  canAdd,
  onAdd,
  addText = '+ Add',
  defaultTabText = 'Tab',
  setSelectedTab,
  selectedTab,
  allowOnlyTabToBeDeleted = false,
  move,
}) => {
  const { errors: formErrors, touched: formTouched, values: formValues } = useFormikContext<Record<string, any>>();

  const values = formValues[name];
  const fieldArrayErrors = formErrors?.[name] || [];
  const fieldArrayTouched = Array.isArray(formTouched?.[name]) ? formTouched?.[name] : [formTouched?.[name] || false];
  const tabErrors = useMemo(() => {
    // @ts-ignore
    return arrayTabHasError(fieldArrayErrors, fieldArrayTouched);
  }, [fieldArrayErrors, fieldArrayTouched]);

  const hasError = tabErrors.some((error) => error);

  const onTabClick = (idx: number) => () => {
    setSelectedTab(idx);
  };

  const handleRemove = (idx: number, canDelete?: boolean) =>
    canDelete
      ? () => {
          if (selectedTab === idx) {
            const nextIndex = Math.max(0, selectedTab - 1);
            setSelectedTab(nextIndex);
          }

          if (onRemove) onRemove(idx);
        }
      : undefined;

  return (
    <Flex css={styles} className={cs('field-array-tab-bar')} data-tab-focus-selector={hasError}>
      <DraggableList
        reorderDroppedItem={move}
        classNames={{
          container: cs('field-array-tab-bar__tab-buttons', 'hideScrollbar'),
          itemDragging: 'field-array-tab-bar__draggable-tab--dragging',
          item: 'field-array-tab-bar__draggable-tab',
        }}
        dropIndicatorOffset="-7px"
        endDropIndicatorOffset="-5px"
      >
        {values?.map((item, idx) => {
          const hasTabError = tabErrors[idx];
          const canDeleteTag = canRemove && (allowOnlyTabToBeDeleted || values.length > 1);

          return (
            <Tag
              key={`${item.id}-${idx}`}
              data-id={item.id}
              css={[tabCss]}
              theme="outline"
              className={cs('field-array-tab-bar__tab', {
                'field-array-tab-bar__tab--error': hasTabError,
                'field-array-tab-bar__tab--active': selectedTab === idx,
              })}
              onClick={onTabClick(idx)}
              onDelete={handleRemove(idx, canDeleteTag)}
              promptText="Are you sure you want to delete this module?"
              promptTitle="Confirm Module Deletion"
            >
              {defaultTabText + ' ' + (idx + 1)}
            </Tag>
          );
        })}
        <Button
          type="button"
          className="field-array-tab-bar__add-btn"
          data-dragging-disabled
          variant={BUTTON_VARIANTS.TERTIARY}
          disabled={!canAdd}
          onClick={onAdd}
        >
          {addText}
        </Button>
      </DraggableList>
    </Flex>
  );
};

export default FieldArrayTabBar;
