import React from 'react';
import ReactHTMLParser from 'react-html-parser';

import { useCreators } from 'features/creators/useCreators';
import PromptButton from 'shared/components/molecules/PromptButton';

import { CREATOR_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { CreatorUser } from 'shared/typings/user';

interface Props {
  creator: CreatorUser;
  accountId: number;
  briefId: number;
}
const RemoveCreatorFromBriefButton: React.FC<Props> = ({ creator, accountId, briefId }) => {
  const { rejectCreator } = useCreators();

  const rejectCreatorFromBrief = () => {
    rejectCreator({ creatorId: creator.id, briefId, accountId });
  };

  return (
    <PromptButton
      onConfirm={rejectCreatorFromBrief}
      title={CREATOR_COPY.BUTTON_REMOVE_CREATOR}
      text={ReactHTMLParser(CREATOR_COPY.MODAL_REMOVE)}
      variant={BUTTON_VARIANTS.OPTION}
    >
      {CREATOR_COPY.BUTTON_REMOVE_CREATOR}
    </PromptButton>
  );
};

export default RemoveCreatorFromBriefButton;
