import { Brief } from 'shared/typings/briefs';
import { Module } from 'shared/typings/briefs';
import { BrkfstFile } from 'shared/typings/file';
import { ModuleComment } from 'shared/typings/moduleComments';
import { Revision } from 'shared/typings/revisions';
import { BrkfstUser } from 'shared/typings/user';

export interface Submission {
  id: number;
  status: SubmissionStatus;
  briefId: number;
  numApprovedAssets: number;
  moduleSubmissions: ModuleSubmission[];
  assets: Array<BrkfstFile>;
  brief: Brief;
  dateCreated: Date;
  dateSubmitted: Date | null;
  description: string;
  userCreated: number;
  important: boolean;
  creator: BrkfstUser;
  hasBeenSeen: boolean;
  creatorId: number;
  paid?: boolean;
}

export enum SubmissionStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
}

export interface ModuleSubmission {
  id: number;
  moduleId: number;
  submissionId: number;
  status: ModuleSubmissionStatus;
  files: BrkfstFile[];
  revisions?: Revision[];
  comments?: ModuleComment[];
  moduleNum: number;
  module?: Pick<Module, 'name'>;
}

export enum ModuleSubmissionStatus {
  APPROVED = 'APPROVED',
  SUBMITTED = 'SUBMITTED',
  REVIEW = 'REVIEW',
  REVISION = 'REVISION',
}

export type ReviewModule = ModuleSubmission & {
  moduleNum: number;
  creatorName: string;
  creatorId: number;
};
