import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '&.date-input': {
    input: {
      padding: '0.5rem 0.75rem',
      fontSize: '1rem',
      border: `1px solid ${COLORS.NEUTRAL300}`,
      margin: '.75rem 0 .25rem',
      borderRadius: '2px',
      '&::placeholder': {
        color: COLORS.NEUTRAL700,
      },
    },
    '.date-input': {
      '&__caption': {
        color: COLORS.NEUTRAL500,
        textTransform: 'none',
        margin: '.75rem 0 0 0',
      },
      '&__input': {
        position: 'relative',
      },
      '&__day-picker': {
        position: 'absolute',
        top: '15px',
        left: '150px',
      },
    },
    '.input-mask--invalid': {
      borderColor: COLORS.RED300,
    },
    '&::placeholder': {
      color: COLORS.NEUTRAL700,
    },
  },
});
