import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import CreatorCollection from 'features/media/pages/CreatorCollection/CreatorCollection';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import PayoutCheckout from 'features/users/components/organisms/PayoutCheckout/PayoutCheckout';
import { useUser } from 'features/users/hooks/useUser';
import CreatorProfile from 'features/users/pages/CreatorProfile/CreatorProfile';
import CreatorShowcase from 'features/users/pages/CreatorShowcase';

import { UserRouteParams } from 'shared/config/routes/brands/matchTypes';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { CreatorUser } from 'shared/typings/user';

const CreatorRoutes = () => {
  const params = useParams<UserRouteParams>();
  const userId = +params.userId;

  const { user, loading, isUserCreator, fetchUser, getUserAddress, clearUser, displayTags } = useUser(userId);
  const { lastVisitedOrg } = useCurrentUser();

  useEffect(() => {
    if (userId) fetchUser(userId, lastVisitedOrg);
    return () => clearUser();
  }, [userId, lastVisitedOrg]);

  useEffect(() => {
    if (userId && isUserCreator) getUserAddress(userId);
  }, [userId, isUserCreator]);

  if (loading) {
    return <CenteredSpinnerContainer />;
  }

  return (
    <Routes>
      {isUserCreator && (
        <>
          <Route index element={<CreatorProfile user={user as CreatorUser} creatorTags={displayTags} />} />
          <Route path="payout" element={<PayoutCheckout />} />
          <Route path="library/:collectionId" element={<CreatorCollection brandView />} />
          <Route path="approved-assets" element={<CreatorShowcase user={user} brandView />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
  );
};

export default CreatorRoutes;
