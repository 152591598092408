import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import searchBg from 'shared/images/search-bg.png';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';
import { STYLES } from 'shared/styles/styles';
import theme from 'shared/styles/themes';

export default (width?: string | number) =>
  css({
    '&.dropdown': {
      cursor: 'pointer',
      '& .tippy-content': {
        padding: 0,
      },
      '&__content-wrapper': {
        width,
        zIndex: zIndex.zDropdownPortal,
        backgroundColor: COLORS.WHITE,
        boxShadow: SHADOWS.SHADOW04,
        overflowY: 'auto',
        maxHeight: 'fit-content',
        whiteSpace: 'normal',
        display: 'none',
      },
      '&__content-wrapper--open': {
        display: 'block',
      },
      '&__content-wrapper--all-rounded': {
        borderRadius: '5px',
      },
      '&__content-wrapper--bottom-end, &__content-wrapper--bottom-start': {
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      '&__content-wrapper--top-end, &__content-wrapper--top-start': {
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
      },
    },
    '.dropdown': {
      '&__search-box': {
        padding: theme.space[3],
        borderBottom: STYLES.BORDER_NEUTRAL,
        position: 'relative',
      },
      '&__search-bar': {
        color: COLORS.BLACK,
        fontSize: '1rem',
        padding: '0.25rem 0.5rem 0.25rem 2rem',
        border: `1px solid ${COLORS.NEUTRAL200}`,
        minWidth: '0',
        width: '100%',
        background: `${COLORS.WHITE} url(${searchBg}) 8px 9px no-repeat`,
        backgroundSize: '14px 14px',
        backgroundPosition: '8px 8px',
        margin: '0',
      },
      '&__search-bar::placeholder': {
        color: COLORS.NEUTRAL300,
      },
      '&__option': {
        height: 'fit-content',
      },
      '&__footer': {
        borderTop: STYLES.BORDER_NEUTRAL,
      },
    },
  });
