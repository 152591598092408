import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import Billing from 'features/organizations/pages/Billing';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { OrganizationRouteParams } from 'shared/config/routes/brands/matchTypes';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePermissions } from 'shared/hooks/usePermissions';
import lazyWithRetry from 'shared/lib/lazyWithRetry';

import OrgSettingsLayout from './OrgSettingsLayout';

const PaymentSettings = lazyWithRetry(() => import('features/organizations/pages/PaymentSettings'));
const ManageUsers = lazyWithRetry(() => import('features/organizations/pages/ManageUsers/ManageUsers'));
const ManageRoles = lazyWithRetry(() => import('features/organizations/pages/ManageRoles/ManageRoles'));
const EditOrg = lazyWithRetry(() => import('features/organizations/pages/EditOrg'));

const OrgSettingRoutes = () => {
  const params = useParams<OrganizationRouteParams>();
  const organizationId = +params.organizationId;
  const { displayComponent } = usePermissions();
  const { isSuperadmin } = useCurrentUser();

  return (
    <Routes>
      <Route element={<OrgSettingsLayout organizationId={organizationId} />}>
        <Route index element={<Billing />} />
        <Route path="cards" element={<PaymentSettings />} />
        <Route path="users" element={<ManageUsers />} />
        {isSuperadmin && <Route path="roles" element={<ManageRoles />} />}
        {displayComponent(<Route path="edit" element={<EditOrg />} />, organizationId, PERMISSIONS_MAP.EDIT_ORG.API)}
      </Route>
      <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
  );
};

export default OrgSettingRoutes;
