import { useDispatch, useSelector } from 'react-redux';

import { TRENDS_LIBRARY_COPY } from 'features/trends/pages/TrendsLibrary/copy';
import {
  displayTrendCleared,
  displayTrendLoaded,
  getDisplayTrend,
  getPinnedCount,
  getTrend,
  getTrends,
  getTrendsTotal,
  trendAdded,
  trendPinned,
  trendRemoved,
  trendsLoaded,
  trendUnpinned,
  trendUpdated,
} from 'features/trends/trends.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_TREND_ROUTES } from 'shared/config/routes/api/apiTrendRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useToasts } from 'shared/hooks/useToasts';
import { intl } from 'shared/lib/intl';
import { Trend } from 'shared/typings/trends';

const NEW_TREND = 'New Trend';
const REMOVE_TREND = 'Remove Trend';
const PIN_TREND = 'Pin Trend';
const DISPLAY_TREND = 'Display Trend';

const PIN_TREND_MAX = 10;

export const useTrends = (id?: number) => {
  const dispatch = useDispatch();

  const trends: Trend[] = useSelector(getTrends);
  const trend: Trend | undefined = useSelector(getTrend(id));
  // trend used when linked trend is not already loaded
  const displayTrend: Trend | null = useSelector(getDisplayTrend);
  const pinnedCount: number = useSelector(getPinnedCount);
  const totalTrends = useSelector(getTrendsTotal);

  const { loading } = useComponentLoading(RESOURCES.TREND, true);
  const { loading: loadingNewTrend } = useComponentLoading(NEW_TREND, false);
  const { loading: loadingRemoveTrend } = useComponentLoading(REMOVE_TREND, false);
  const { loading: loadingPinTrend } = useComponentLoading(PIN_TREND, false);
  const { loading: loadingDisplayTrend } = useComponentLoading(DISPLAY_TREND, true);

  const { setErrorToast } = useToasts();

  const fetchDisplayTrend = (id: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_TREND_ROUTES.TREND,
          variables: { trendId: id },
        },
        successAction: displayTrendLoaded,
        entity: DISPLAY_TREND,
      }),
    );
  };

  const clearDisplayTrend = () => {
    dispatch(displayTrendCleared({}));
  };

  const fetchTrends = (query: {
    page: number;
    size: number;
    orderAsc: boolean;
    orderBy: string;
    accountId: string;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_TREND_ROUTES.TRENDS,
        },
        params: query,
        successAction: trendsLoaded,
        entity: RESOURCES.TREND,
      }),
    );
  };

  const createTrend = (
    trend: { name: string; fileId: number; isBrkfstAsset: boolean },
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_TREND_ROUTES.TRENDS,
        },
        method: 'POST',
        data: trend,
        successAction: trendAdded,
        entity: NEW_TREND,
        successToast: {
          message: TRENDS_LIBRARY_COPY.TOAST_CREATE_TREND_SUCCESS,
        },
        onSuccess,
        onError,
      }),
    );
  };

  const updateTrend = (id: number, trend: { name: string; fileId: number; deleteFile?: boolean }) => {
    dispatch(
      apiAction({
        path: {
          route: API_TREND_ROUTES.TREND,
          variables: { trendId: id },
        },
        method: 'PATCH',
        data: trend,
        successAction: trendUpdated,
        entity: RESOURCES.TREND,
        successToast: {
          message: TRENDS_LIBRARY_COPY.TOAST_UPDATE_TREND_SUCCESS,
        },
      }),
    );
  };

  const deleteTrend = (id: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_TREND_ROUTES.TREND,
          variables: { trendId: id },
        },
        method: 'DELETE',
        successAction: trendRemoved,
        entity: REMOVE_TREND,
        successToast: {
          message: TRENDS_LIBRARY_COPY.TOAST_DELETE_TREND_SUCCESS,
        },
      }),
    );
  };

  const pinTrend = (trendId: number, accountId: number) => {
    if (PIN_TREND_MAX > pinnedCount) {
      dispatch(
        apiAction({
          path: {
            route: API_TREND_ROUTES.PIN_TREND,
            variables: { trendId, accountId },
          },
          method: 'POST',
          successAction: trendPinned,
          entity: PIN_TREND,
        }),
      );
    } else {
      setErrorToast({
        message: intl.formatMessage(
          {
            id: 'TOAST_PIN_TREND_MAX',
          },
          { max: PIN_TREND_MAX },
        ),
      });
    }
  };

  const unpinTrend = (trendId: number, accountId: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_TREND_ROUTES.PIN_TREND,
          variables: { trendId, accountId },
        },
        method: 'DELETE',
        successAction: trendUnpinned,
        entity: PIN_TREND,
      }),
    );
  };

  const sendBriefLaunchEmail = (accountId: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_TREND_ROUTES.SEND_BRIEF_LAUNCH_EMAIL,
        },
        method: 'POST',
        data: {
          accountId,
        },
        successToast: {
          message: TRENDS_LIBRARY_COPY.TOAST_BRIEF_LAUNCH_SUCCESS,
        },
      }),
    );
  };

  return {
    clearDisplayTrend,
    createTrend,
    deleteTrend,
    displayTrend,
    fetchDisplayTrend,
    fetchTrends,
    loading,
    loadingDisplayTrend,
    loadingNewTrend,
    loadingPinTrend,
    loadingRemoveTrend,
    pinnedCount,
    maxPinLimitReached: pinnedCount >= PIN_TREND_MAX,
    pinTrend,
    sendBriefLaunchEmail,
    totalTrends,
    trend,
    trends,
    unpinTrend,
    updateTrend,
  };
};
