import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

const ADD_BTN_WIDTH = '81px';

export const tabCss = css({
  '&.field-array-tab-bar__tab': {
    '&--active': {
      color: COLORS.PRIMARY500,
      border: `1px solid ${COLORS.PRIMARY500}`,
    },
    '&--error': {
      border: `1px solid ${COLORS.RED500}`,
      color: COLORS.RED500,
    },
    '&--error:hover': {
      border: `1px solid ${COLORS.RED500}`,
      color: COLORS.RED500,
      backgroundColor: COLORS.RED50,
    },
    '&--error:hover svg': {
      color: COLORS.RED500,
    },
  },
});

export default css({
  '&.field-array-tab-bar': {
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  '.field-array-tab-bar': {
    '&__draggable-tab': {
      flexShrink: 0,
    },
    '&__draggable-tab--dragging': {
      opacity: 0.7,
      border: `1px dashed ${COLORS.NEUTRAL300}`,
      borderRadius: '4px',
    },
    '&__tab-buttons': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '14px',
      flexGrow: 1,
      overflow: 'auto',
      flexWrap: 'wrap',
    },
    '&__add-btn': {
      width: ADD_BTN_WIDTH,
      minWidth: ADD_BTN_WIDTH,
    },
  },
});
