import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export const BASE_CLASS = 'input';

export const MODIFIERS = {
  NO_MARGIN: '--no-margin',
  SUBMITTED: '--submitted',
  NO_BORDER: '--no-border',
  INVALID: '--invalid',
};

export default css({
  [`&.${BASE_CLASS}`]: {
    padding: '0.5rem 0.75rem',
    fontSize: '1rem',
    border: `1px solid ${COLORS.NEUTRAL300}`,
    margin: '.75rem 0 .25rem',
    borderRadius: '2px',
    width: '100%',

    '&:not(:disabled):active &:not(:disabled):focus': {
      borderColor: COLORS.PRIMARY500,
    },
    '&:disabled': {
      padding: '0.5rem 2rem 0.5rem 0.75rem',
      borderColor: COLORS.NEUTRAL200,
      background: COLORS.WHITE,
      color: COLORS.NEUTRAL300,
    },

    [`&${MODIFIERS.NO_MARGIN}`]: {
      marginTop: 0,
      marginBottom: 0,
    },

    [`&${MODIFIERS.SUBMITTED}`]: {
      borderColor: COLORS.RED500,
    },

    [`&${MODIFIERS.NO_BORDER}`]: {
      border: 'none',
    },
    [`&${MODIFIERS.INVALID}`]: {
      borderColor: COLORS.RED500,
    },
  },
});
