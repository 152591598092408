import React, { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

import { isItemInList } from './util';
import { ItemData } from './types';

const itemKey = Symbol('item');

interface Props {
  children: React.ReactElement;
  disabled?: boolean;
  draggingClassName?: string;
  id: number | string;
  index: number;
  instanceId: symbol;
  className?: string;
  axis: 'vertical' | 'horizontal';
}

const EDGES_BEFORE: (string | null)[] = ['bottom', 'left'];
const EDGES_AFTER: (string | null)[] = ['top', 'right'];

const DraggableItem: React.FC<Props> = ({
  children: Child,
  disabled = false,
  draggingClassName = 'draggable-item--dragging',
  id,
  index,
  instanceId,
  className,
  axis,
}) => {
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

  const data: ItemData = {
    id,
    index,
    itemKey,
    instanceId,
  };

  useEffect(() => {
    const element = ref.current;

    if (disabled || !element) {
      return;
    }

    return combine(
      //   setting up item to be draggable
      draggable({
        element,
        getInitialData: () => data,
        onDragStart: () => {
          setDragging(true);
        },
        onDrop: () => {
          setDragging(false);
        },
      }),
      //   setting up item to also be a drop target
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return isItemInList(source.data, instanceId);
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: axis === 'vertical' ? ['top', 'bottom'] : ['left', 'right'],
          });
        },
        onDrag({ self, source }) {
          // source is the dropped item

          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;

          if (typeof sourceIndex === 'number') {
            const isItemBeforeSource = index === sourceIndex - 1;
            const isItemAfterSource = index === sourceIndex + 1;

            const isDropIndicatorHidden =
              (isItemBeforeSource && EDGES_AFTER.includes(closestEdge)) ||
              (isItemAfterSource && EDGES_BEFORE.includes(closestEdge));

            if (isDropIndicatorHidden) {
              setClosestEdge(null);
              return;
            }

            setClosestEdge(closestEdge);
          }
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [instanceId, data, index, disabled]);

  if (disabled) {
    return Child;
  }

  return (
    <Box ref={ref} className={cs('draggable-item', className, { [draggingClassName]: dragging })}>
      {React.cloneElement(Child, {
        className: cs(Child.props.className, { [draggingClassName]: dragging }),
      })}
      {closestEdge && (
        <span className={cs('draggable-item__drop-indicator', `draggable-item__drop-indicator--${closestEdge}`)} />
      )}
    </Box>
  );
};

export default DraggableItem;
