import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import config from 'config';
import styled from 'styled-components';

import { useAuth } from 'features/auth/useAuth';
import UserDropdown from 'features/home/components/molecules/UserDropdown';
import MessageNavItem from 'features/home/MessageNavItem';
import SwitchOrganizations from 'features/organizations/components/organisms/SwitchOrganizations';
import { useOrganizations } from 'features/organizations/useOrganizations';
import { useUser } from 'features/users/hooks/useUser';
import ChangeUserTypeButton from 'shared/components/atoms/ChangeUserTypeButton';
import NavBar from 'shared/components/atoms/NavBar';
import NavBarLogo from 'shared/components/atoms/NavBarLogo';
import SignOutButton from 'shared/components/atoms/SignOutButton';
import { Flex } from 'shared/components/display';
import PromptButton from 'shared/components/molecules/PromptButton';

import { ORGANIZATION_COPY, USER_COPY } from 'shared/config/copy';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { LinkCreator } from 'shared/utilities/linkUtility';

const RightWrapper = styled(Flex)`
  align-items: center;
`;

const LOCAL_STORAGE_KEYS = [LOCAL_STORAGE.ORGANIZATION_ONBOARDING, LOCAL_STORAGE.FB_BUSINESS_ID];

const BrandNavBar = () => {
  const location = useLocation<any>();
  const navigate = useNavigate();
  const { lastVisitedOrg: lastVisitedOrgId, isSuperadmin, currentUser } = useCurrentUser();
  const { organizations } = useOrganizations();
  const { deleteUser } = useUser(currentUser.id);
  const { signOut } = useAuth();
  const [showSwitchModal, setShowSwitchModal] = useState(false);

  const { organizationId, isOrgOnboarding, hasOrganizations, organization } = useMemo(() => {
    const matches = new RegExp(`^/org/(\\d+)/?`).exec(location.pathname);
    const orgId = matches ? +matches[1] : lastVisitedOrgId;
    return {
      organizationId: orgId,
      organization: orgId ? organizations.find((org) => org.id === orgId) : null,
      hasOrganizations: organizations.length > 0,
      isOrgOnboarding: location.pathname.includes('org-onboarding'),
    };
  }, [location.pathname, lastVisitedOrgId, organizations]);

  const showMessageIcon = organization?.chatEnabled || isSuperadmin;

  const onDeleteUser = () => {
    deleteUser(signOut);
  };

  return (
    <NavBar>
      <SwitchOrganizations
        isOpen={showSwitchModal}
        onRequestClose={() => {
          setShowSwitchModal(false);
        }}
        organizationId={organizationId}
      />
      <NavBarLogo disableNavigate={!hasOrganizations} />
      <RightWrapper>
        {showMessageIcon && <MessageNavItem organizationId={organizationId} />}
        <UserDropdown>
          {hasOrganizations && (
            <Button
              onClick={() => {
                setShowSwitchModal(true);
              }}
              data-cy="brand-nav-bar__switch-org-button"
            >
              {ORGANIZATION_COPY.OPTION_SWITCH}
            </Button>
          )}

          {hasOrganizations && (
            <Button
              onClick={() => {
                navigate(
                  LinkCreator.createLink({
                    routeKey: 'ORG_SETTINGS',
                    variables: {
                      organizationId,
                    },
                  }),
                );
              }}
              data-cy="brand-nav-bar__org-settings-button"
            >
              {ORGANIZATION_COPY.OPTION_SETTINGS}
            </Button>
          )}

          {isSuperadmin && (
            <Button
              onClick={() => {
                navigate(
                  LinkCreator.createLink({
                    routeKey: 'EDIT_BRIEF_TEMPLATES',
                    variables: {
                      organizationId,
                    },
                  }),
                );
              }}
            >
              {ORGANIZATION_COPY.OPTION_EDIT_BRIEFS}
            </Button>
          )}

          {hasOrganizations && (
            <Button
              onClick={() => {
                navigate(
                  LinkCreator.createLink({
                    routeKey: 'USER_SETTINGS',
                  }),
                );
              }}
              data-cy="brand-nav-bar__user-settings-button"
            >
              {USER_COPY.BUTTON_USER_SETTINGS}
            </Button>
          )}
          {!hasOrganizations && <ChangeUserTypeButton localStorageKeys={LOCAL_STORAGE_KEYS} />}

          {config.FEATURE_FLAG_DELETE_USER && !isSuperadmin && (
            <PromptButton onConfirm={onDeleteUser} text={USER_COPY.MODAL_HEADING_DELETE_USER}>
              {USER_COPY.BUTTON_DELETE_USER}
            </PromptButton>
          )}

          <SignOutButton localStorageKeys={isOrgOnboarding ? LOCAL_STORAGE_KEYS : []} />
        </UserDropdown>
      </RightWrapper>
    </NavBar>
  );
};

export default BrandNavBar;
