import React, { useEffect, useMemo } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import config from 'config';

import TrendCopyLink from 'features/trends/components/molecules/TrendCopyLink';
import TrendEllipsisMenu from 'features/trends/components/molecules/TrendEllipsisMenu';
import TrendGallery from 'features/trends/components/organisms/TrendGallery';
import { useTrendFileNotifications } from 'features/trends/hooks/useTrendFileNotifications';
import { useTrends } from 'features/trends/hooks/useTrends';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Flex, Text } from 'shared/components/display';
import TrendCarouselItem from 'shared/components/molecules/CarouselItem/TrendCarouselItem';
import PromptButton from 'shared/components/molecules/PromptButton';
import { Lightbox } from 'shared/components/organisms/Lightbox';
import Pagination from 'shared/components/organisms/Pagination';

import { TRACKING } from 'shared/config/tracking';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { trackEvent } from 'shared/utilities/trackingUtility';

import { TRENDS_LIBRARY_COPY } from './copy';
import styles from './styles';

const TrendsLibrary: React.FC = () => {
  const {
    trends,
    clearDisplayTrend,
    displayTrend,
    fetchTrends,
    loading,
    loadingNewTrend,
    loadingPinTrend,
    sendBriefLaunchEmail,
    totalTrends,
    fetchDisplayTrend,
    pinnedCount,
  } = useTrends();
  const { isSuperadmin } = useCurrentUser();
  const { accountId } = useParams<{ accountId: string }>();
  useTrendFileNotifications();
  const { getQuery } = useQuery();
  const query = getQuery();
  const { trendId } = query;
  const searchParams = useSearchParams(query, {
    page: 1,
    size: config.TRENDS_PER_PAGE,
    orderAsc: true,
    orderBy: 'name',
  });

  useEffect(() => {
    // trend to be displayed in the lightbox when navigating from a shared link since we cant guarantee the trend is loaded on the current page
    if (!loading) {
      const trendIsLoaded = trends.some((trend) => trend.id === +trendId);
      if (trendId && !trendIsLoaded) {
        fetchDisplayTrend(+trendId);
      }
    }
  }, [trendId, trends, loading]);

  useEffect(() => {
    fetchTrends({ ...searchParams, accountId });
    // refetch trends when a trend is pinned/unpinned
  }, [loadingPinTrend, searchParams, accountId]);

  const onConfirm = () => {
    sendBriefLaunchEmail(+accountId);
    trackEvent(TRACKING.BRIEF_LAUNCH_REQUESTED, {
      brandId: accountId,
    });
  };

  const disabledLaunchButton = useMemo(() => {
    return pinnedCount < 2;
  }, [pinnedCount, loadingPinTrend]);

  return (
    <Flex css={styles} className={cs('trends-library')}>
      <Text as="h1" variant={TEXT_VARIANTS.H1} className="trends-library__header">
        <FormattedMessage
          id="TREND_LIBRARY_HEADER"
          values={{
            pink: (chunks) => <span className="trends-library__text--pink">{chunks}</span>,
          }}
        />
      </Text>
      <Flex className="trends-library__control-bar">
        <Flex>
          <Tooltip content={TRENDS_LIBRARY_COPY.TOOLTIP_LAUNCH_BUTTON} maxWidth={300} disabled={!disabledLaunchButton}>
            <PromptButton
              className="trends-library__control-bar__launch-button"
              variant="primary"
              onConfirm={onConfirm}
              title={TRENDS_LIBRARY_COPY.HEADER_LAUNCH_BRIEF}
              text={ReactHTMLParser(TRENDS_LIBRARY_COPY.DESCRIPTION_LAUNCH_BRIEF)}
              disabled={disabledLaunchButton}
            >
              {TRENDS_LIBRARY_COPY.BUTTON_LAUNCH_BRIEF}
            </PromptButton>
          </Tooltip>
          <Tooltip content={TRENDS_LIBRARY_COPY.TOOLTIP_INFO_ICON} maxWidth={280}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              color={COLORS.NEUTRAL400}
              className="trends-library__control-bar__info-icon"
            />
          </Tooltip>
        </Flex>

        <Flex>
          {isSuperadmin && <TrendEllipsisMenu showNewButton />}
          <TrendCopyLink />
        </Flex>
      </Flex>
      <Pagination totalAmount={totalTrends} searchParams={searchParams} hidePageSizeSelect>
        <TrendGallery
          items={trends}
          loading={loading}
          loadingNew={loadingNewTrend}
          accountId={+accountId}
          trendId={+trendId}
        />
      </Pagination>
      <Lightbox
        isOpen={Boolean(displayTrend)}
        selectedItemIndex={0}
        ariaLabel="Trend Preview"
        onRequestClose={clearDisplayTrend}
      >
        {[
          <TrendCarouselItem
            key={displayTrend?.id || 'none'}
            url={displayTrend?.file.url}
            thumbnailUrl={displayTrend?.file.thumbnailUrl}
            mimeType={displayTrend?.file.mimeType || ''}
            metadata={displayTrend?.file.metadata}
            extension={displayTrend?.file.extension}
            trendName={displayTrend?.name || ''}
            trendId={displayTrend?.id || 0}
            onRequestClose={clearDisplayTrend}
            isSuperadmin={isSuperadmin}
          />,
        ]}
      </Lightbox>
    </Flex>
  );
};

export default TrendsLibrary;
