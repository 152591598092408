import React from 'react';
import { Field } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';

import InstagramConnect from 'features/users/InstagramConnect/InstagramConnect';
import { Box } from 'shared/components/display';
import SocialMediaHandleInput from 'shared/components/SocialMediaHandleInput/SocialMediaHandleInput';
import TextInput from 'shared/components/TextInput';

import { CREATOR_COPY } from 'shared/config/copy';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { YUP_FIELDS } from 'shared/utilities/yupFieldUtility';

interface Props {
  currentUser: BrkfstUser;
}

export const PortfolioOnboardingStepFields: React.FC<Props> = ({ currentUser }) => {
  return (
    <Box className="portfolio-step">
      <InstagramConnect
        userId={currentUser.id}
        hasSocialProfile={!!currentUser.instagramProfile}
        redirectURI={LinkCreator.createLink({
          userType: UserType.CREATOR,
          routeKey: 'CREATOR_ONBOARDING',
        })}
      />
      <Field
        name={PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK}
        component={TextInput}
        label="Portfolio Link"
        placeholder="Portfolio Link"
      />
      <Field
        name={PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE}
        label="TikTok Handle"
        placeholder="TikTok Handle"
        component={SocialMediaHandleInput}
        className="portfolio-step__tiktok"
      />
    </Box>
  );
};

export const PORTFOLIO_FIELDNAMES = {
  PORTFOLIO_LINK: 'portfolioLink',
  TIKTOK_HANDLE: 'tiktokHandle',
};

export const getPortfolioSchema = (hasIGProfile: boolean) =>
  yup.object({
    [PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK]: YUP_FIELDS.PORTFOLIO_LINK_OPTIONAL,
    [PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE]: yup.string().nullable().tiktokHandle(),
  });

export const portfolioInitialValues = {
  [PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK]: '',
  [PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE]: '',
};
