import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cs from 'classnames';
import BriefAcceptInviteButton from 'features/briefs/components/atoms/BriefAcceptInviteButton';
import BriefApplicationButton from 'features/briefs/components/molecules/BriefApplicationButton';
import { useSubmissions } from 'features/submissions/useSubmissions';
import { componentLoadingToggled } from 'features/ui/ui.slice';
import { Brief } from 'shared/typings/briefs';
import { BRIEF_COPY, SUBMISSION_COPY } from 'shared/config/copy';
import { RESOURCES } from 'shared/config/resourceNames';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { BriefStatus, BriefTypes } from 'shared/typings/briefs/enums';
import { UserType } from 'shared/typings/user/enums';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { Box, Button, Text, Flex } from 'shared/components/display';
import { TEXT_VARIANTS } from 'shared/styles/text';
import styles from './styles';
import { SubmissionStatus } from 'shared/typings/submissions';

interface Props {
  brief: Pick<
    Brief,
    'id' | 'briefCreatorSubmission' | 'briefCreatorStatus' | 'inviteOnly' | 'full' | 'type' | 'status'
  >;
}

const MobileBriefCreatorButtons: React.FC<Props> = ({ brief }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const { createSubmission } = useSubmissions();

  const briefIsClosed = brief.status === BriefStatus.CLOSED;
  const isDraft = brief.briefCreatorSubmission?.status === SubmissionStatus.DRAFT;

  const submissionIsEditable = useMemo(() => {
    if (briefIsClosed) return false;
    if (isDraft) return true;

    const hasApprovedAssets = brief?.briefCreatorSubmission?.assets?.reduce(
      (result, file) => file.approved || result,
      false,
    );

    return !hasApprovedAssets;
  }, [brief, briefIsClosed, isDraft]);

  const linkToAccountSettings = (): void => {
    const path = LinkCreator.createLink({
      userType: UserType.CREATOR,
      routeKey: 'ACCOUNT_SETTINGS',
      variables: {
        userId: currentUser.id,
      },
    });
    navigate(path);
  };

  const navigateToSubmissionForm = () => {
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.SUBMISSION,
        loading: true,
      }),
    );
    navigate(
      LinkCreator.createLink({
        userType: UserType.CREATOR,
        routeKey: 'CREATE_UPDATE_SUBMISSION',
        variables: {
          submissionId: brief.briefCreatorSubmission?.id,
        },
      }),
    );
  };

  switch (brief.briefCreatorStatus) {
    case UserBriefStatus.NOT_APPLIED:
      if (brief.inviteOnly) {
        return (
          <Flex css={styles} className="mobile-brief-creator-buttons">
            <Text className="mobile-brief-creator-buttons__text" variant={TEXT_VARIANTS.SUBHEADING}>
              {BRIEF_COPY.LABEL_INVITE_ONLY}
            </Text>
            <Button variant={BUTTON_VARIANTS.PRIMARY} className="mobile-brief-creator-buttons__btn" disabled>
              {BRIEF_COPY.BUTTON_APPLY_BRIEF}
            </Button>
          </Flex>
        );
      }
      if (brief.full) {
        return (
          <Flex css={styles} className="mobile-brief-creator-buttons">
            <Text className="mobile-brief-creator-buttons__text" variant={TEXT_VARIANTS.SUBHEADING}>
              {BRIEF_COPY.LABEL_FULL_DISPLAY}
            </Text>
            <Button variant={BUTTON_VARIANTS.PRIMARY} className="mobile-brief-creator-buttons__btn" disabled>
              {BRIEF_COPY.BUTTON_APPLY_BRIEF}
            </Button>
          </Flex>
        );
      }
      return (
        <Box css={styles} className="mobile-brief-creator-buttons">
          <BriefApplicationButton
            className="mobile-brief-creator-buttons__btn"
            userId={currentUser.id}
            briefId={brief.id}
            disabled={briefIsClosed}
          />
        </Box>
      );
    case UserBriefStatus.ACTIVE:
      if (!currentUser.stripeAccountId && brief.type === BriefTypes.BRAND) {
        return (
          <Button
            onClick={linkToAccountSettings}
            css={styles}
            className={cs('mobile-brief-creator-buttons__btn', 'mobile-brief-creator-buttons__btn--white')}
            variant={BUTTON_VARIANTS.OUTLINE}
          >
            {BRIEF_COPY.BUTTON_CONNECT_STRIPE}
          </Button>
        );
      }
      if (briefIsClosed) return null;
      return (
        <Button onClick={() => createSubmission(brief.id)} css={styles} className="mobile-brief-creator-buttons__btn">
          {BRIEF_COPY.BUTTON_SUBMIT_BRIEF}
        </Button>
      );
    case UserBriefStatus.DRAFTED:
      return (
        <Button
          css={styles}
          disabled={!submissionIsEditable}
          className="mobile-brief-creator-buttons__btn"
          onClick={navigateToSubmissionForm}
        >
          {BRIEF_COPY.BUTTON_COMPLETE_BRIEF_SUBMISSION}
        </Button>
      );
    case UserBriefStatus.SUBMITTED:
    case UserBriefStatus.REVISION:
      if (briefIsClosed) {
        return null;
      }
      return (
        <Button
          css={styles}
          onClick={navigateToSubmissionForm}
          className="mobile-brief-creator-buttons__btn"
          data-cy="mobile-brief-creator-buttons__edit-btn"
        >
          {isDraft ? SUBMISSION_COPY.BUTTON_EDIT_SUBMISSION_DRAFT : SUBMISSION_COPY.BUTTON_EDIT_SUBMISSION}
        </Button>
      );
    case UserBriefStatus.INVITED:
      return (
        <Box css={styles} className="mobile-brief-creator-buttons">
          <BriefAcceptInviteButton
            className="mobile-brief-creator-buttons__btn"
            userId={currentUser.id}
            briefId={brief.id}
          />
        </Box>
      );
    default:
      return null;
  }
};

export default MobileBriefCreatorButtons;
