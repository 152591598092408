import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import cs from 'classnames';

import PinFileControls from 'features/media/components/molecules/PinFileControls';
import { useTrends } from 'features/trends/hooks/useTrends';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import HoverOverlay from 'shared/components/atoms/HoverOverlay';
import SingleImage from 'shared/components/atoms/SingleImage';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box, Flex, Text } from 'shared/components/display';
import TrendCarouselItem from 'shared/components/molecules/CarouselItem/TrendCarouselItem';
import { Lightbox } from 'shared/components/organisms/Lightbox';

import { TRACKING } from 'shared/config/tracking';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { Trend } from 'shared/typings/trends';
import { trackEvent } from 'shared/utilities/trackingUtility';
import brkfstLogo from 'shared/images/logo/brkfstio-logo.svg';

import styles from './styles';

interface Props {
  items: Trend[];
  loading?: boolean;
  loadingNew?: boolean;
  accountId: number;
  trendId?: number;
}
const TrendGallery: React.FC<Props> = ({ items, loading, loadingNew, accountId, trendId }) => {
  const [lightbox, setLightbox] = useState<{ open: boolean; index: number }>({
    open: false,
    index: 0,
  });

  const { isSuperadmin } = useCurrentUser();
  const { pinTrend, unpinTrend, maxPinLimitReached } = useTrends();

  const onPin = (id: number, trendName: string, isPinned: boolean) => () => {
    if (isPinned) {
      unpinTrend(id, accountId);
    } else {
      pinTrend(id, accountId);
      trackEvent(TRACKING.TREND_PINNED, {
        trendName,
      });
    }
  };

  useEffect(() => {
    if (trendId) {
      const index = items.findIndex((item) => item.id === trendId);
      if (index !== -1) {
        setLightbox({ open: true, index });
      }
    }
  }, [trendId, items]);

  const onRequestClose = () => {
    setLightbox((prev) => ({ ...prev, open: false }));
  };

  const onOpenTrend = (index: number, trendName: string) => {
    setLightbox({ open: true, index });
    trackEvent(TRACKING.TREND_CLICKED, {
      brandId: accountId,
      trendName,
    });
  };

  return (
    <>
      <Box
        css={styles}
        className={cs('trend-gallery', {
          'trend-gallery--loading': loading,
        })}
      >
        {loading ? (
          <CenteredSpinner />
        ) : (
          <>
            {items.map(({ file, name, isPinned = false, id, isBrkfstAsset }, index) => {
              const layout = {
                height: 400,
                width: 225,
                index,
              };
              return (
                <Box key={file.id} className="trend-gallery__tile" dataCy={`trend-gallery__tile--${name}`}>
                  <HoverOverlay
                    OverlayComponent={
                      <Flex className="trend-gallery__tile-overlay">
                        <PinFileControls
                          isPinned={isPinned}
                          onPin={onPin(id, name, isPinned)}
                          maxPinLimitReached={maxPinLimitReached}
                          canPin
                        />
                        {isBrkfstAsset && (
                          <Flex
                            className="trend-gallery__brkfst-asset-label"
                            dataCy="trend-gallery__brkfst-asset-label"
                          >
                            <img src={brkfstLogo} alt="brkfst asset" className="trend-gallery__label-logo" />
                          </Flex>
                        )}
                      </Flex>
                    }
                    top="10px"
                    left="10px"
                    right="10px"
                  >
                    <Box className="trend-gallery__tile-content" onClick={() => onOpenTrend(index, name)}>
                      <SingleImage
                        name={name}
                        source={file.thumbnailUrl}
                        width={`${layout.width}px`}
                        height={`${layout.height}px`}
                      />
                    </Box>
                  </HoverOverlay>
                  <Tooltip content={name}>
                    <Text className={cs('trend-gallery__trend-name', 'endWithEllipsis')}>{name}</Text>
                  </Tooltip>
                </Box>
              );
            })}
            {loadingNew && <Skeleton width={225} height={400} />}
          </>
        )}
      </Box>
      <Lightbox
        isOpen={lightbox.open}
        selectedItemIndex={lightbox.index}
        ariaLabel="Trend Preview"
        onRequestClose={onRequestClose}
      >
        {items.map(({ name, file, id }) => (
          <TrendCarouselItem
            key={id}
            url={file.url}
            thumbnailUrl={file.thumbnailUrl}
            mimeType={file.mimeType}
            metadata={file.metadata}
            extension={file.extension}
            trendName={name}
            trendId={id}
            onRequestClose={onRequestClose}
            isSuperadmin={isSuperadmin}
          />
        ))}
      </Lightbox>
    </>
  );
};

export default TrendGallery;
