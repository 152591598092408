import React from 'react';
import { useDispatch } from 'react-redux';

import { userChanged } from 'features/users/hooks/user.slice';
import PromptButton from 'shared/components/molecules/PromptButton';

import { apiAction } from 'shared/actions/api';
import { CREATOR_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { CreatorUser } from 'shared/typings/user';

interface Props {
  user: CreatorUser;
  className?: string;
  onClick?: () => void;
}

const DeactivateCreatorButton: React.FC<Props> = ({ user, className, onClick }) => {
  const dispatch = useDispatch();
  const { inProgressBriefsCount = 0 } = user;

  const title =
    inProgressBriefsCount > 0 ? CREATOR_COPY.PROMPT_DEACTIVATE_USER_DSIABLED : CREATOR_COPY.PROMPT_DEACTIVATE_USER;

  const text =
    inProgressBriefsCount > 0
      ? `Cannot deactivate this creator. This creator has (${inProgressBriefsCount}) active briefs`
      : CREATOR_COPY.PROMPT_DEACTIVATION_USER_DETAIL;

  const confirmText = inProgressBriefsCount > 0 ? 'OK' : 'Confirm';

  const onConfirm = () => {
    if (inProgressBriefsCount < 1)
      dispatch(
        apiAction({
          path: {
            route: '/user/:userId/deactivate',
            variables: { userId: user.id },
          },
          method: 'PATCH',
          successToast: {
            message: CREATOR_COPY.SUCCESS_DEACTIVATE_USER,
          },
          successAction: userChanged,
        }),
      );
    onClick && onClick();
  };

  return (
    <PromptButton
      modalSize={SIZING.MEDIUM}
      title={title}
      text={text}
      onConfirm={onConfirm}
      ariaLabel={CREATOR_COPY.BUTTON_DEACTIVATE_CREATOR}
      variant={BUTTON_VARIANTS.OPTION}
      confirmButtonText={confirmText}
      className={className}
      data-cy="elite-creator-button"
    >
      {CREATOR_COPY.BUTTON_DEACTIVATE_CREATOR}
    </PromptButton>
  );
};

export default DeactivateCreatorButton;
