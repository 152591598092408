import React from 'react';
import cs from 'classnames';

import VettingApprovalButtons from 'features/creators/components/atoms/VettingApprovalButtons';
import BrandApprovedBadge from 'features/users/components/atoms/BrandApprovedBadge';
import EliteCreatorBadge from 'features/users/components/atoms/EliteCreatorBadge';
import EliteCreatorButton from 'features/users/components/atoms/EliteCreatorButton';
import SpotlightCreatorBadge from 'features/users/components/atoms/SpotlightCreatorBadge';
import SpotlightCreatorButton from 'features/users/components/atoms/SpotlightCreatorButton';
import CreatorUserFields from 'features/users/components/CreatorUserFields/CreatorUserFields';
import InternalNotesForm from 'features/users/components/InternalNotesForm/InternalNotesForm';
import CreatorTags from 'features/users/components/molecules/CreatorTags/CreatorTags';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import CreatorInviteFormModal from 'features/users/components/organisms/CreatorInviteFormModal';
import PayoutForm from 'features/users/components/organisms/PayoutForm/PayoutForm';
import CreatorAddress from 'features/users/CreatorAddress';
import { Box, Flex, Text } from 'shared/components/display';
import EllipticalMenu from 'shared/components/EllipticalMenu/EllipticalMenu';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';

import { USER_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstTag } from 'shared/typings/tag';
import { CreatorUser } from 'shared/typings/user';
import { VettingStatus } from 'shared/typings/user/enums';
import { createAgeGenderLabel } from 'shared/utilities/stringUtility';

import DeactivateCreatorButton from '../../atoms/DeactivateCreatorButton';

import creatorInfoPanelStyles from './creatorInfoPanelStyles';

interface Props {
  user: CreatorUser;
  creatorTags: BrkfstTag[];
}
const MarketerView: React.FC<Props> = ({ user, creatorTags }) => {
  const { isSuperadmin } = useCurrentUser();

  const genderAgeLabel = createAgeGenderLabel(user);

  return (
    <Box as="section" css={creatorInfoPanelStyles} className={cs('profile-panel', 'profile-panel--white')}>
      <Box className={cs('profile-panel__item', 'profile-panel__modal-form-btn')}>
        <Flex className="profile-panel__marketer-btn-wrapper">
          <CreatorInviteFormModal
            creatorEmail={user.email}
            creatorId={user.id}
            disabled={user.vettingStatus === VettingStatus.REJECTED}
          />
          {isSuperadmin && (
            <EllipticalMenu
              id={'profile-panel__admin-btn-group'}
              className="profile-panel__admin-btn-group"
              dataCy="profile-panel__admin-btn-group"
              width="225px"
            >
              <PayoutForm user={user} />
              <InternalNotesForm user={user} />
              <EliteCreatorButton className="profile-panel__elite" user={user} />
              <SpotlightCreatorButton className="profile-panel__spotlight" user={user} />
              <DeactivateCreatorButton className="profile-panel__spotlight" user={user} />
              <VettingApprovalButtons creator={user} portalTarget="#profile-panel__admin-btn-group" />
            </EllipticalMenu>
          )}
        </Flex>
        <ProfilePicture user={user} size="lg" disableNavigate />
      </Box>
      <h4 className={cs('profile-panel__header', 'profile-panel__header--centered')}>
        {`${user.firstName} ${user.lastName || ''}`}
      </h4>
      {(user.age || user.gender) && (
        <Text className="profile-panel__gender-age" variant={TEXT_VARIANTS.BODY}>
          {genderAgeLabel}
        </Text>
      )}
      <Flex className="profile-panel__badge-wrapper">
        {user.approvedAssetBadge && <BrandApprovedBadge showTooltip />}
        {user.isElite && <EliteCreatorBadge showTooltip />}
        {user.isSpotlighted && <SpotlightCreatorBadge showTooltip />}
      </Flex>
      {user.summary && <Text className="profile-panel__item">{user.summary}</Text>}
      <Box className={cs('profile-panel__item', 'profile-panel__tags')}>
        <CreatorTags tags={creatorTags} />
      </Box>
      {isSuperadmin && (
        <Box className="profile-panel__item">
          <DefinitionList>
            <ListDetail text="Internal Note" hide={!user.note}>
              {user.note || ''}
            </ListDetail>
            <ListDetail text="Email">
              <a href={`mailto:${user.email}`} target="_blank">
                {user.email}
              </a>
            </ListDetail>
            <ListDetail text={USER_COPY.LABEL_ACTIVE_BRIEFS}>
              {`${(user as CreatorUser)?.inProgressBriefsCount || 0}`}
            </ListDetail>
          </DefinitionList>
        </Box>
      )}
      <Box className="profile-panel__item">
        <CreatorUserFields user={user} />
        <CreatorAddress userId={user.id} />
      </Box>
    </Box>
  );
};

export default MarketerView;
