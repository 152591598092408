import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';

import { nonTouchScreenMediaquery } from './styleFunctions';

const {
  PRIMARY100,
  PRIMARY200,
  PRIMARY500,
  PRIMARY600,
  PRIMARY700,
  NEUTRAL50,
  NEUTRAL100,
  NEUTRAL200,
  NEUTRAL300,
  NEUTRAL800,
  WHITE,
  BLACK,
  TRANSPARENT,
} = COLORS;
const { SHADOW03 } = SHADOWS;

const buttons = {
  primary: {
    color: WHITE,
    border: '1px solid transparent',
    borderRadius: '4px',
    backgroundColor: PRIMARY500,
    cursor: 'pointer',
    fontWeight: 600,
    height: 'min-content',
    px: [5],
    py: [3],
    minWidth: '9rem',

    '&:not(:disabled) svg': {
      color: WHITE,
    },
    '&:not(:disabled):hover': {
      backgroundColor: PRIMARY700,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  primarySmall: {
    color: WHITE,
    border: '1px solid transparent',
    borderRadius: '4px',
    backgroundColor: PRIMARY500,
    cursor: 'pointer',
    fontWeight: 500,
    height: 'min-content',
    px: [4],
    py: [2],
    fontSize: [1],

    '&:not(:disabled) svg': {
      color: WHITE,
    },
    '&:not(:disabled):hover': {
      backgroundColor: PRIMARY700,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  tertiary: {
    color: PRIMARY500,
    border: '1px solid transparent',
    borderRadius: '4px',
    backgroundColor: PRIMARY100,
    cursor: 'pointer',
    fontWeight: 600,
    height: 'min-content',
    px: [5],
    py: [3],
    minWidth: '9rem',

    '&:not(:disabled) svg': {
      color: WHITE,
    },
    '&:not(:disabled):hover': {
      backgroundColor: PRIMARY200,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  secondary: {
    color: NEUTRAL800,
    backgroundColor: WHITE,
    cursor: 'pointer',
    fontWeight: 600,
    height: 'min-content',
    px: [5],
    py: [3],
    minWidth: '9rem',
    borderRadius: '4px',
    border: `1px solid ${NEUTRAL300}`,

    '&:not(:disabled) svg': {
      color: NEUTRAL800,
    },
    '&:not(:disabled):hover': {
      color: PRIMARY600,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  secondarySmall: {
    color: NEUTRAL800,
    backgroundColor: WHITE,
    cursor: 'pointer',
    fontWeight: 500,
    height: 'min-content',
    px: [4],
    py: [2],
    fontSize: [1],
    borderRadius: '4px',
    border: `1px solid ${NEUTRAL300}`,

    '&:not(:disabled) svg': {
      color: NEUTRAL800,
    },
    '&:not(:disabled):hover': {
      color: PRIMARY600,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  outline: {
    color: PRIMARY500,
    borderRadius: '4px',
    border: `1px solid ${PRIMARY500}`,
    backgroundColor: TRANSPARENT,
    cursor: 'pointer',
    fontWeight: 600,
    height: 'min-content',
    px: 'calc(1rem - 2px)',
    py: 'calc(0.5rem - 2px)',
    minWidth: '8rem',

    '&:not(:disabled) svg': {
      color: PRIMARY500,
    },
    '&:not(:disabled):hover, &:not(:disabled):hover svg': {
      color: WHITE,
      backgroundColor: PRIMARY500,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  plain: {
    cursor: 'pointer',
    border: `1px solid ${NEUTRAL200}`,
    backgroundColor: TRANSPARENT,
    px: 'calc(1rem - 1px)',
    py: 'calc(0.5rem - 1px)',
    color: NEUTRAL800,
    fontWeight: 400,
    height: 'min-content',
    minWidth: '8rem',

    '&:not(:disabled) svg': {
      color: NEUTRAL800,
    },

    '&:not(:disabled):hover': {
      boxShadow: SHADOW03,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  micro: {
    color: NEUTRAL800,
    border: `1px solid ${NEUTRAL200}`,
    backgroundColor: WHITE,
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: [1, 1, 0],
    height: 'min-content',
    px: [3],
    py: [2],

    '& svg': {
      fontSize: [1, 1, 0],
    },
    '&:not(:disabled) svg': {
      color: NEUTRAL800,
    },
    '&:not(:disabled):hover, &:not(:disabled):hover svg': {
      color: NEUTRAL800,
      backgroundColor: NEUTRAL100,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  /* default state will only show visible content,
    but on hover shows a background color */
  text: {
    color: BLACK,
    backgroundColor: TRANSPARENT,
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'normal',
    height: 'min-content',
    px: [5],
    py: [3],
    minWidth: '8rem',

    [nonTouchScreenMediaquery]: {
      '&:not(:disabled):hover': {
        backgroundColor: NEUTRAL50,
        color: PRIMARY500,
      },
      '&:not(:disabled):hover svg': {
        color: PRIMARY500,
      },
    },

    '&:not(:disabled):focus': {
      boxShadow: 'none',
      backgroundColor: PRIMARY100,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  /* different from text variant in that on hover,
    it will only change the color of the content,
    does not change the background color */
  textOnly: {
    color: BLACK,
    backgroundColor: TRANSPARENT,
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'normal',
    height: 'min-content',
    px: [5],
    py: [3],
    boxShadow: 'none',

    '&:not(:disabled):hover, &:not(:disabled):hover svg': {
      backgroundColor: TRANSPARENT,
      color: PRIMARY500,
    },
    '&:not(:disabled):focus, &:not(:disabled):focus svg': {
      color: PRIMARY600,
      backgroundColor: TRANSPARENT,
      boxShadow: 'none',
      outline: 'none',
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  // Used for dropdowns like Ellipsis
  option: {
    color: 'currentColor',
    backgroundColor: TRANSPARENT,
    border: 'none',
    borderRadius: 0,
    cursor: 'pointer',
    width: '100%',
    fontSize: [2],
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.8,
    px: [5],
    py: [3],
    textAlign: 'left',
    userSelect: 'none',

    '&:not(:disabled):hover': {
      backgroundColor: NEUTRAL50,
      color: 'initial',
    },
    '&:not(:disabled):focus': {
      transform: 'none',
      color: PRIMARY500,
      outline: 'none',
    },
    '&:not(:disabled).highlighted': {
      color: PRIMARY500,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  flatOption: {
    color: 'currentColor',
    backgroundColor: TRANSPARENT,
    border: 'none',
    borderRadius: 0,
    cursor: 'pointer',
    width: '100%',
    fontSize: [1],
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.8,
    px: [5],
    py: [1],
    textAlign: 'left',
    userSelect: 'none',

    '&:not(:disabled):focus': {
      transform: 'none',
      color: PRIMARY500,
      outline: 'none',
    },
    '&:not(:disabled).highlighted': {
      color: PRIMARY500,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
    '&:not(:disabled):hover': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
  },
  dropdownFooter: {
    color: PRIMARY500,
    backgroundColor: TRANSPARENT,
    border: 'none',
    cursor: 'pointer',
    fontWeight: 600,
    height: 'min-content',
    px: [5],
    py: [3],
    boxShadow: 'none',
    fontSize: [2],
    width: '100%',
    textAlign: 'left',

    '&:not(:disabled):hover, &:not(:disabled):hover svg': {
      backgroundColor: NEUTRAL50,
      color: PRIMARY500,
    },
    '&:not(:disabled):focus, &:not(:disabled):focus svg': {
      backgroundColor: NEUTRAL50,
      boxShadow: 'none',
      outline: 'none',
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  tool: {
    cursor: 'pointer',
    border: `1px solid ${NEUTRAL200}`,
    backgroundColor: WHITE,
    px: [4],
    py: [3],
    color: NEUTRAL800,
    fontSize: [1],
    fontWeight: 600,
    height: 'min-content',
    borderRadius: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:not(:disabled) svg': {
      color: NEUTRAL800,
    },
    '& svg:first-of-type': {
      mr: [3],
    },
    '& svg:last-of-type': {
      ml: [3],
    },
    '&:not(:disabled):hover': {
      boxShadow: SHADOW03,
      color: PRIMARY500,
      '& svg': {
        color: PRIMARY500,
      },
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.3,
    },
  },
  invisible: {
    visibility: 'hidden',
  },
};

export default buttons;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BUTTON_VARIANTS {
  PRIMARY = 'primary',
  PRIMARY_SMALL = 'primarySmall',
  TERTIARY = 'tertiary',
  SECONDARY = 'secondary',
  SECONDARY_SMALL = 'secondarySmall',
  OUTLINE = 'outline',
  PLAIN = 'plain',
  MICRO = 'micro',
  TEXT = 'text',
  TEXT_ONLY = 'textOnly',
  OPTION = 'option',
  FLAT_OPTION = 'flatOption', // no hover side effects
  DROPDOWN_FOOTER = 'dropdownFooter',
  TOOL = 'tool',
  INVISIBLE = 'invisible',
}
