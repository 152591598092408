import { CSSObject } from '@emotion/react';
import React, { useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset as offsetFunc,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cs from 'classnames';

import { Box } from 'shared/components/display';

import styles from './styles';

export type PopoverOffset = {
  mainAxis?: number;
  crossAxis?: number;
};

interface Props {
  allRounded?: boolean;
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  ClickComponent: React.ReactElement;
  contentClassName?: string;
  contentStyles?: CSSObject;
  disabled?: boolean;
  offset?: PopoverOffset;
  onOpen?: (open: boolean) => void;
  placement?: Placement;
  width?: string | number;
  maxHeight?: string | number;
  flipEnabled?: boolean;
}
const Popover: React.FC<Props> = ({
  allRounded,
  children,
  className,
  ClickComponent,
  contentStyles,
  contentClassName,
  disabled,
  offset,
  onOpen,
  placement = 'bottom-end',
  width,
  maxHeight = '14rem',
  flipEnabled = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
    onOpen && onOpen(open);
  };

  const {
    refs,
    floatingStyles,
    context,
    placement: finalPlacement,
  } = useFloating({
    open: isOpen,
    onOpenChange,
    middleware: [
      offsetFunc(offset),
      ...(flipEnabled
        ? [
            flip({
              padding: 5,
            }),
          ]
        : []),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
    placement,
  });
  const click = useClick(context, { enabled: !disabled });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <Box
      className={cs('brkfst-popover', className)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {React.cloneElement(
        ClickComponent,
        getReferenceProps({
          ref: refs.setReference,
          ...ClickComponent.props,
          'data-state': isOpen ? 'open' : 'closed',
          className: ClickComponent.props?.className,
        }),
      )}
      {isOpen && (
        <FloatingPortal id="body">
          <Box
            css={styles(width, maxHeight, contentStyles)}
            ref={refs.setFloating}
            className={cs(
              contentClassName,
              'brkfst-popover__content-wrapper',
              `brkfst-popover__content-wrapper--${finalPlacement}`,
              {
                'brkfst-popover__content-wrapper--all-rounded': allRounded,
              },
            )}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {children}
          </Box>
        </FloatingPortal>
      )}
    </Box>
  );
};

export default Popover;
