import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default (color?: string) =>
  css({
    '&.tag': {
      backgroundColor: COLORS.PRIMARY50,
      color: color || COLORS.PRIMARY500,
      justifyContent: 'space-between',
      padding: `${theme.space[2]} ${theme.space[4]}`,
      width: 'fit-content',
      fontWeight: 500,
      height: 'fit-content',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
      '&--default': {
        borderRadius: '18px',
        border: `1px solid ${COLORS.PRIMARY500}`,
      },
      '&--compact': {
        borderRadius: '5px',
        padding: `${theme.space[2]} ${theme.space[3]}`,
      },
    },
    '&.tag--outline': {
      color: COLORS.NEUTRAL700,
      borderRadius: '4px',
      border: `1px solid ${COLORS.NEUTRAL300}`,
      backgroundColor: COLORS.TRANSPARENT,
      cursor: 'pointer',
      fontWeight: 600,
      height: 'min-content',
      padding: 'calc(0.5rem - 2px) calc(1rem - 2px)',
      '&:hover': {
        backgroundColor: COLORS.NEUTRAL50,
      },
      '& .tag__remove-icon': {
        color: COLORS.NEUTRAL700,
      },
      '& .tag__remove-btn': {
        display: 'none',
      },
      '&:hover .tag__remove-btn': {
        display: 'inline',
      },
    },
    '.tag': {
      '&__remove-icon': {
        color: COLORS.NEUTRAL400,
        width: '7px',
        cursor: 'pointer',
      },
      '&__remove-btn': {
        padding: 0,
        margin: `0 0 0 ${theme.space[3]}`,
      },
    },
  });
