import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cs from 'classnames';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import RemoveCreatorFromBriefButton from 'features/briefs/components/atoms/RemoveCreatorFromBriefButton';
import ApprovalButtons from 'features/briefs/components/molecules/ApprovalButtons';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import BrandApprovedBadge from 'features/users/components/atoms/BrandApprovedBadge';
import EliteCreatorBadge from 'features/users/components/atoms/EliteCreatorBadge';
import SpotlightCreatorBadge from 'features/users/components/atoms/SpotlightCreatorBadge';
import CreatorTags from 'features/users/components/molecules/CreatorTags/CreatorTags';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import MessageButton from 'shared/components/atoms/MessageButton';
import { Box, Button, Flex, Text } from 'shared/components/display';
import EllipticalMenu from 'shared/components/EllipticalMenu/EllipticalMenu';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';

import { BRIEF_COPY, CREATOR_COPY, USER_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { CreatorUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { prettyDate } from 'shared/utilities/dateUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { createAgeGenderLabel } from 'shared/utilities/stringUtility';

import styles from './styles';
import { useOrganizations } from 'features/organizations/useOrganizations';

interface Props {
  creator: CreatorUser;
  accountId: number;
  briefId: number;
  loading?: boolean;
  className?: string;
}

const BriefCreatorInfo: React.FC<Props> = ({ creator, accountId, loading = false, briefId, className }) => {
  const { isSuperadmin } = useCurrentUser();
  const { account } = useAccounts({ accountId: accountId.toString() });
  const { brief } = useBriefs(briefId);
  const { organization } = useOrganizations(brief.organizationId);

  const showMessageButton =
    [UserBriefStatus.ACTIVE, UserBriefStatus.SUBMITTED, UserBriefStatus.DRAFTED].includes(
      creator.creatorBriefStatus || UserBriefStatus.NOT_APPLIED,
    ) &&
    (isSuperadmin || organization?.chatEnabled);

  const profileLink = LinkCreator.createLink({
    userType: UserType.CREATOR,
    routeKey: 'USER_PROFILE',
    variables: {
      userId: creator.id,
    },
  });

  const openProfile = () => {
    window.open(profileLink, '_blank');
  };

  const showRemoveCreatorButton =
    creator.creatorBriefStatus &&
    [UserBriefStatus.ACTIVE, UserBriefStatus.SUBMITTED, UserBriefStatus.REVISION, UserBriefStatus.DRAFTED].includes(
      creator.creatorBriefStatus,
    );

  const tags = useMemo(() => {
    return [...(creator.fauxTags || []), ...(creator.creatorTags || [])];
  }, [creator.creatorTags, creator.fauxTags]);

  if (loading) {
    return (
      <Box className={cs('brief-creator-info', className)} css={styles}>
        <CenteredSpinner />
      </Box>
    );
  }

  return (
    <Box className={cs('brief-creator-info', className)} css={styles}>
      {creator.creatorBriefStatus === UserBriefStatus.INVITED ? (
        <Text variant={TEXT_VARIANTS.H4} className="brief-creator-info__invited-creator">
          {CREATOR_COPY.PLACEHOLDER_INVITED_CREATOR_NOT_ACCEPTED}
        </Text>
      ) : (
        <>
          <Box className="brief-creator-info__header">
            <Flex className="brief-creator-info__name-buttons">
              <Box>
                <Flex className="brief-creator-info__name-badges">
                  <Link
                    className={cs('brief-creator-info__creator-name', 'endWithEllipsis')}
                    to={profileLink}
                    target="_blank"
                  >{`${creator.firstName} ${creator.lastName}`}</Link>
                  {creator.brandApprovedBadge && <BrandApprovedBadge showTooltip />}
                  {creator.isElite && <EliteCreatorBadge showTooltip />}
                  {creator.isSpotlighted && <SpotlightCreatorBadge showTooltip />}
                </Flex>
                {(creator.age || creator.gender) && (
                  <Text className="brief-creator-info__age-gender">{createAgeGenderLabel(creator)}</Text>
                )}
              </Box>
              <ApprovalButtons creator={creator} brief={brief} />
              {isSuperadmin && showRemoveCreatorButton && (
                <EllipticalMenu
                  id={'brief-creator-info__admin-btn-group'}
                  className="brief-creator-info__admin-btn-group"
                  dataCy="brief-creator-info__admin-btn-group"
                  width="225px"
                  placement="bottom-start"
                >
                  <RemoveCreatorFromBriefButton creator={creator} accountId={accountId} briefId={briefId} />
                </EllipticalMenu>
              )}
            </Flex>
            <Flex className="brief-creator-info__pic">
              <ProfilePicture user={creator} size="md" customProfileLink={profileLink} openNewTab />
            </Flex>
          </Box>
          <DefinitionList labelColor={COLORS.NEUTRAL500}>
            <ListDetail
              detailsClassName="brief-creator-info__application-pitch"
              labelClassName="brief-creator-info__application-pitch-label"
              text={CREATOR_COPY.LABEL_CREATOR_MESSAGE}
              hide={!creator.applicationPitch?.length}
            >
              {creator.applicationPitch || ''}
            </ListDetail>
            <ListDetail text="Tags" hide={!tags.length} hideLabel>
              <CreatorTags tags={tags} theme="top-category" className="brief-creator-info__tags" />
            </ListDetail>
            <ListDetail text={CREATOR_COPY.COLUMN_CREATOR_APPLIED_DATE}>
              {creator.creatorBriefDateCreated ? prettyDate(creator.creatorBriefDateCreated) : '-'}
            </ListDetail>

            <ListDetail text={USER_COPY.LABEL_ACTIVE_BRIEFS} hide={!isSuperadmin}>
              {`${creator.inProgressBriefsCount || 0}`}
            </ListDetail>
            <ListDetail hide={!Boolean(creator.stateCodes?.length)} text="Able to Travel">
              {creator.stateCodes?.length ? creator.stateCodes.join(', ') : '-'}
            </ListDetail>
          </DefinitionList>
          <Flex className="brief-creator-info__footer">
            <Button
              onClick={openProfile}
              variant={BUTTON_VARIANTS.PRIMARY_SMALL}
              className="brief-creator-info__view-profile-button"
            >
              {BRIEF_COPY.BUTTON_VIEW_FULL_PROFILE}
            </Button>
            {showMessageButton && (
              <MessageButton user={creator} account={account} variant={BUTTON_VARIANTS.SECONDARY_SMALL} />
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default BriefCreatorInfo;
