import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default (dropIndicatorOffset: string, endDropIndicatorOffset?: string) =>
  css({
    '&.draggable-list': {
      overflow: 'visible',
    },
    '.draggable-item': {
      position: 'relative',
      cursor: 'grab',
      '&__drop-indicator': {
        position: 'absolute',
        backgroundColor: COLORS.PRIMARY500,
      },
      '&__drop-indicator--top, &__drop-indicator--bottom': {
        height: '2px',
        width: '100%',
        left: 0,
        right: 0,
      },
      '&__drop-indicator--left, &__drop-indicator--right': {
        height: '100%',
        width: '2px',
        top: 0,
        bottom: 0,
      },
      '&__drop-indicator--top': {
        top: dropIndicatorOffset,
      },
      '&__drop-indicator--bottom': {
        bottom: dropIndicatorOffset,
      },
      '&__drop-indicator--right': {
        right: dropIndicatorOffset,
      },
      '&__drop-indicator--left': {
        left: dropIndicatorOffset,
      },
    },
    '.draggable-item:last-of-type': {
      '& .draggable-item__drop-indicator--bottom': {
        bottom: endDropIndicatorOffset || dropIndicatorOffset,
      },
      '& .draggable-item__drop-indicator--right': {
        right: endDropIndicatorOffset || dropIndicatorOffset,
      },
    },
    '.draggable-item:first-of-type': {
      '& .draggable-item__drop-indicator--top': {
        top: endDropIndicatorOffset || dropIndicatorOffset,
      },
      '& .draggable-item__drop-indicator--left': {
        left: endDropIndicatorOffset || dropIndicatorOffset,
      },
    },
  });
