import React from 'react';

import BriefModule from 'features/briefs/components/atoms/BriefModule';
import { ShippingLink } from 'features/briefs/components/atoms/ShippingLink';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box, Text } from 'shared/components/display';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import FileListView, { FileAction } from 'shared/components/molecules/FileListView/FileListView';
import Heading from 'shared/components/molecules/Heading';
import Richtext from 'shared/components/Richtext';

import { BRIEF_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { intl } from 'shared/lib/intl';
import COLORS from 'shared/styles/colors';
import { Brief } from 'shared/typings/briefs';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { prettyStringDate } from 'shared/utilities/dateUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';

import styles from './styles';

interface Props {
  brief: Brief;
  stickyLabel?: boolean;
  labelColor?: string;
  uppercase?: boolean;
  showBriefAssets?: boolean;
}
const BriefFields: React.FC<Props> = ({
  brief,
  stickyLabel = false,
  labelColor = COLORS.NEUTRAL500,
  uppercase = false,
  showBriefAssets = false,
}) => {
  const {
    isCreator,
    isMarketer,
    isSuperadmin,
    currentUser: { isElite },
  } = useCurrentUser();
  const isApprovedCreator = brief.briefCreatorStatus === UserBriefStatus.ACTIVE;
  const isSubmittedCreator = brief.briefCreatorStatus === UserBriefStatus.SUBMITTED;
  const isOtherCreator = [
    UserBriefStatus.NOT_APPLIED,
    UserBriefStatus.PENDING,
    UserBriefStatus.REJECTED,
    UserBriefStatus.INVITED,
  ].includes(brief.briefCreatorStatus);

  return (
    <Box dataCy="brief-fields" className="brief-fields" css={styles}>
      <DefinitionList uppercase={uppercase} stickyLabel={stickyLabel} labelColor={labelColor}>
        <ListDetail text={BRIEF_COPY.FORM_NAME_HEADING}>{brief.name}</ListDetail>
        <ListDetail text={BRIEF_COPY.FORM_PRODUCT_HEADING}>
          {brief.freeProduct || BRIEF_COPY.PLACEHOLDER_NO_PRODUCT}
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_SHIPPING_REQUIRED} hide={Boolean(brief.shippingLink)}>
          {brief.shippingRequired ? 'Yes' : 'No'}
        </ListDetail>
        <ListDetail hide={!Boolean(brief.states?.length)} text={BRIEF_COPY.FORM_PRODUCT_INSTORE}>
          {brief.states?.map((state) => state.code).join(', ') || 'N/A'}
        </ListDetail>
        <ListDetail
          text={BRIEF_COPY.LABEL_SHIPPING_REQUIRED}
          hide={!brief.shippingRequired || !Boolean(brief.shippingLink)}
        >
          <ShippingLink link={brief.shippingLink} />
        </ListDetail>
        <ListDetail
          text={BRIEF_COPY.LABEL_PROMO_CODE}
          hide={!brief.promotionalCode || (!isApprovedCreator && isCreator)}
        >
          {brief.promotionalCode}
        </ListDetail>
        <ListDetail
          text={BRIEF_COPY.LABEL_PROMO_CODE}
          hide={!brief.promotionalCode || isMarketer || !isSubmittedCreator}
        >
          {BRIEF_COPY.LABEL_SUBMISSION_COMPLETED}
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_PROMO_CODE} hide={!brief.promotionalCode || isMarketer || !isOtherCreator}>
          {BRIEF_COPY.LABEL_VIEWABLE_UPON_APPROVAL}
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_WEBSITE} hide={!brief.website}>
          <ExternalLink href={brief.website} />
        </ListDetail>
        <ListDetail
          dataCy="brief-fields__payout"
          text={BRIEF_COPY.LABEL_CREATOR_PAYOUT}
          hide={isCreator ? isElite : !isSuperadmin}
        >
          {intl.formatMessage(
            {
              id: 'DETAIL_PERFORMANCE_PAYOUT',
            },
            {
              payout: formatCurrency(brief.type === 0 ? 0 : brief.creatorPayout),
              creativeOnly: brief.creativeOnly,
            },
          )}
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_ELITE_CREATOR_PAYOUT} hide={isCreator ? !isElite : !isSuperadmin}>
          {intl.formatMessage(
            {
              id: 'DETAIL_PERFORMANCE_PAYOUT',
            },
            {
              payout: formatCurrency(brief.type === 0 ? 0 : brief.eliteCreatorPayout),
              creativeOnly: brief.creativeOnly,
            },
          )}
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_CREATOR_APPROVAL_PRICING} hide={isCreator}>
          {formatCurrency(brief.type === 0 ? 0 : brief.creatorApprovalPricing)}
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_ELITE_CREATOR_APPROVAL_PRICING} hide={isCreator}>
          {formatCurrency(brief.type === 0 ? 0 : brief.eliteCreatorApprovalPricing)}
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_INVITE_ONLY}>{brief.inviteOnly ? 'Yes' : 'No'}</ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_DEADLINE} hide={!brief.creatorDeadline}>
          {brief.creatorDeadline ? prettyStringDate(brief.creatorDeadline) : ''}
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_DESCRIPTION_BRIEF}>
          <Richtext value={JSON.parse(brief.description)} readOnly key={`${brief.id}-description`} />
        </ListDetail>
        {brief?.modules.map((mod) => {
          return (
            <ListDetail
              text={mod.name}
              key={mod.id}
              detailsClassName="brief-fields__module-details"
              labelClassName="brief-fields__module-label"
              color={COLORS.BLACK}
            >
              <BriefModule module={mod} showTags={isMarketer} />
            </ListDetail>
          );
        })}
        <ListDetail text={BRIEF_COPY.HEADING_BRIEF_ASSETS} hide={!showBriefAssets || brief.brandAssets.length === 0}>
          <FileListView
            files={brief.brandAssets}
            action={isMarketer ? FileAction.NONE : FileAction.DOWNLOAD}
            showDownloadAll={isCreator}
          />
        </ListDetail>
        <ListDetail text={BRIEF_COPY.LABEL_AUTO_RESPONSE} hide={isCreator}>
          {brief.autoResponse || BRIEF_COPY.PLACEHOLDER_AUTO_RESPONSE}
        </ListDetail>
      </DefinitionList>
      {isSuperadmin && brief.internalNote && (
        <>
          <hr className="brief-fields__hr" />
          <Heading text={BRIEF_COPY.FORM_HEADING_INTERNAL_NOTE} className="brief-fields__header" />
          <Text className="brief-fields__internal-note" data-cy="brief-fields__internal-note">
            {brief.internalNote}
          </Text>
        </>
      )}
    </Box>
  );
};

export default BriefFields;
