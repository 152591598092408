import { faDollarSign, faEnvelope, faFilm, faMessage, faSuitcase, faUser } from '@fortawesome/pro-light-svg-icons';

import {
  AGE_FIELDNAMES,
  ETHNICITY_FIELDNAMES,
  ethnicityOtherOption,
  formatDependentDOB,
  GENDER_FIELDNAMES,
  genderOtherOption,
  LOCATION_FIELDNAMES,
  MODEL_AVAILABILITY_FIELDNAMES,
  NOTIFICATIONS_FIELDNAMES,
  PORTFOLIO_FIELDNAMES,
  WORK_PREFERENCES_FIELDNAMES,
} from 'features/users/components/CreatorOnboardingStepsFields';
import { HEIGHT_FIELDNAME } from 'features/users/components/CreatorOnboardingStepsFields/HeightFormFields';
import {
  LANGUAGE_FIELDNAMES,
  languageOtherOption,
} from 'features/users/components/CreatorOnboardingStepsFields/LanguageFormFields';
import { ProfileInfoSections } from 'features/users/components/organisms/ProfileInfoForm/config';
import { WorkInfoSections } from 'features/users/components/organisms/WorkInfoForm/config';

import { BrkfstTag } from 'shared/typings/tag';

import { getSelectedValues } from '../Onboarding/CreatorOnboarding';

export type ActiveForm = 'Portfolio' | 'Contact Info' | 'Profile Info' | 'Work Info' | 'Payment' | 'Text Notifications';

export type Section = {
  name: ActiveForm;
  icon: any;
};

export const sections: Section[] = [
  { name: 'Portfolio', icon: faFilm },
  { name: 'Contact Info', icon: faEnvelope },
  { name: 'Profile Info', icon: faUser },
  { name: 'Work Info', icon: faSuitcase },
  { name: 'Payment', icon: faDollarSign },
  { name: 'Text Notifications', icon: faMessage },
];

const convertStringTagToObj = (tag: string) => {
  const [category, value] = tag.split(':');
  return { category, value };
};

export function formatAccountSettingsInfo(
  values,
  activeForm,
  creatorTags: BrkfstTag[] = [],
  activeSubSection?: WorkInfoSections | ProfileInfoSections | null,
) {
  const gender = values[GENDER_FIELDNAMES.GENDER]?.find(({ selected }) => selected)?.value || '';
  const genderOther = values[GENDER_FIELDNAMES.GENDER_OTHER] || '';
  const ethnicityValues = getSelectedValues({
    data: values[ETHNICITY_FIELDNAMES.ETHNICITY],
    prefix: 'ethnicity',
    otherOption: ethnicityOtherOption,
    otherValue: values[ETHNICITY_FIELDNAMES.ETHNICITY_OTHER],
  }).map(convertStringTagToObj);
  const languageValues = getSelectedValues({
    data: values[LANGUAGE_FIELDNAMES.LANGUAGE],
    prefix: 'language',
    otherOption: languageOtherOption,
    otherValue: values[LANGUAGE_FIELDNAMES.LANGUAGE_OTHER],
  }).map(convertStringTagToObj);
  const modelValues = getSelectedValues({ data: values[MODEL_AVAILABILITY_FIELDNAMES.MODELS], prefix: 'model' }).map(
    convertStringTagToObj,
  );
  const locationValues = getSelectedValues({
    data: values[LOCATION_FIELDNAMES.LOCATIONS],
    prefix: 'filming backdrop',
  }).map(convertStringTagToObj);
  const workTypeValues = getSelectedValues({
    data: values[WORK_PREFERENCES_FIELDNAMES.WORK_TYPE],
    prefix: 'work preference',
  }).map(convertStringTagToObj);
  const editingValues = getSelectedValues({ data: values[WORK_PREFERENCES_FIELDNAMES.EDITING], prefix: 'editing' }).map(
    convertStringTagToObj,
  );

  const children = modelValues.find(({ value }) => value === MODEL_AVAILABILITY_FIELDNAMES.CHILDREN)
    ? values[MODEL_AVAILABILITY_FIELDNAMES.CHILDREN]
    : [];

  const teenagers = modelValues.find(({ value }) => value === MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS)
    ? values[MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS]
    : [];

  switch (activeForm) {
    case 'Portfolio':
      return {
        tiktok: values[PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE],
        portfolioLink: values[PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK],
      };
    case 'Text Notifications':
      return {
        notifications: values[NOTIFICATIONS_FIELDNAMES.OPT_IN],
      };
  }

  // for profile and work info sections where there are subsections, use the subsections instead if they exist
  switch (activeSubSection) {
    // *********************** Profile Info Sections *************************
    case 'Date of Birth':
      return {
        birthdate: values[AGE_FIELDNAMES.DOB] || '',
      };
    case 'gender':
      return {
        gender: gender === genderOtherOption.value ? genderOther : gender,
      };
    case 'height':
      return {
        height: values[HEIGHT_FIELDNAME]?.value,
      };
    case 'ethnicity':
    case 'language':
      let existingTags = creatorTags.filter(({ category }) => !['ethnicity', 'language'].includes(category));
      return {
        tags: [...existingTags, ...ethnicityValues, ...languageValues],
        originalTags: creatorTags,
      };
    // ********************************************************************
    // *********************** Work Info Sections *************************
    case 'model':
    case 'workPreferences':
    case 'filmingBackdrop':
      existingTags = creatorTags.filter(
        ({ category }) => !['model', 'filming backdrop', 'work preference', 'editing'].includes(category),
      );
      return {
        tags: [...existingTags, ...modelValues, ...locationValues, ...workTypeValues, ...editingValues],
        originalTags: creatorTags,
        dependents: [...children, ...teenagers].map(({ dob }) => ({ birthdate: formatDependentDOB(dob) })),
      };
    default:
      return null;
  }
}
