// * Util
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import { useRightDrawer } from 'features/ui/useRightDrawer';
import { Flex, Text } from 'shared/components/display';

import { Constants } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

interface Props {
  text: string;
  descriptionText?: string;
  headerText?: string;
  variant?: TEXT_VARIANTS;
  className?: string;
  as?: string;
  dataCy?: string;
  iconColor?: string;
  required?: boolean;
}

/**
 * Displays a Header with an optional info icon that will display the descriptionText in the RightDrawer when clicked
 */
const Heading: React.FC<Props> = ({
  text,
  descriptionText = '',
  headerText = '',
  variant = TEXT_VARIANTS.H4,
  className,
  as,
  dataCy,
  iconColor = COLORS.NEUTRAL600,
  required = false,
}) => {
  const { setRightDrawer } = useRightDrawer(true);

  const toggleInstructionDrawer = (e) => {
    e.stopPropagation();
    setRightDrawer({
      key: text,
      Content: (
        <Text variant={TEXT_VARIANTS.BODY} color={COLORS.NEUTRAL700}>
          {ReactHtmlParser(descriptionText)}
        </Text>
      ),
      headerText: headerText || text,
    });
  };
  const handleKeypress = (keypressEvent) => {
    return keypressEvent.charCode === Constants.KEY_CODES.ENTER_CHAR_CODE && toggleInstructionDrawer(keypressEvent);
  };

  return (
    <Flex css={styles} className={cs('heading', className)} data-cy={dataCy}>
      {
        <Text as={as} className="heading__text" variant={variant}>
          {text}
          {required && (
            <Text as="span" color={COLORS.RED500}>
              &nbsp;*
            </Text>
          )}
        </Text>
      }
      {descriptionText && (
        <FontAwesomeIcon
          color={iconColor}
          icon={faInfoCircle}
          size="sm"
          onClick={toggleInstructionDrawer}
          onKeyPress={handleKeypress}
          tabIndex={0}
          className="heading__icon"
        />
      )}
    </Flex>
  );
};

export default Heading;
