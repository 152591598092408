import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { Address } from 'shared/typings/address';
import { BrkfstTag } from 'shared/typings/tag';
import { BrkfstUser, Phone } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { getAgeFromDate } from 'shared/utilities/dateUtility';

type UserState = BrkfstUser;

type UserAction<T = UserState> = PayloadAction<{ data: T }>;

const formatCreator = (creator: BrkfstUser): BrkfstUser => {
  // @ts-ignore
  return {
    ...creator,
    age: creator.birthdate
      ? `${getAgeFromDate(creator.birthdate)}`
      : creator.creatorTags.find((tag) => tag.category.toLowerCase() === 'age')?.value || '',
    gender: creator.gender
      ? creator.gender
      : creator.creatorTags.find((tag) => tag.category.toLowerCase() === 'gender')?.value || '',
  };
};

const userSlice = createSlice<UserState, SliceCaseReducers<UserState>, 'user'>({
  name: 'user',
  initialState: <BrkfstUser>{},
  reducers: {
    userLoaded: (user, action: UserAction) =>
      action.payload.data.type === UserType.CREATOR ? formatCreator(action.payload.data) : action.payload.data,
    userCleared: (user) => <BrkfstUser>{},
    // @ts-ignore
    userChanged: (user, action: UserAction) => {
      if (user.type === UserType.CREATOR) {
        // @ts-ignore
        return formatCreator({ ...user, ...action.payload.data });
      }
      return { ...user, ...action.payload.data };
    },
    userAccessRejected: () => <BrkfstUser>{},
    profilePictureUploaded: (user, action: UserAction<Pick<BrkfstUser, 'profilePic' | 'profilePicId'>>) => {
      user.profilePic = action.payload.data.profilePic;
      user.profilePicId = action.payload.data.profilePicId;
    },
    profilePictureRemoved: (user) => {
      user.profilePic = undefined;
      user.profilePicId = undefined;
    },
    userTagsUpdated: (user, action: UserAction<Pick<BrkfstUser, 'creatorTags'>>) => {
      user.creatorTags = action.payload.data.creatorTags;
    },
    addressLoaded: (user, action: UserAction<BrkfstUser>) => {
      user.mailingAddress = action.payload.data.mailingAddress;
      user.phone = action.payload.data.phone;
    },
    phoneUpdated: (user, action: UserAction<Phone>) => {
      user.phone = {
        ...user.phone,
        ...action.payload.data,
      };
    },
    addressUpdated: (user, action: UserAction<Address>) => {
      user.mailingAddress = {
        ...user.mailingAddress,
        ...action.payload.data,
      };
    },
    addressAccessRejected: (user) => {
      if (Object.keys(user).length) {
        user.mailingAddress = <Address>{};
      }
    },
    instagramDisconnected: (user) => {
      user.instagramProfile = null;
    },
    tiktokDisconnected: (user) => {
      user.tiktokProfile = null;
    },
  },
});

export const {
  userLoaded,
  userChanged,
  userCleared,
  userAccessRejected,
  profilePictureUploaded,
  profilePictureRemoved,
  userTagsUpdated,
  addressLoaded,
  phoneUpdated,
  addressUpdated,
  addressAccessRejected,
  instagramDisconnected,
  tiktokDisconnected,
} = userSlice.actions;

const reducer = userSlice.reducer;

export const getUser = (state) => state.user;

export default reducer;
