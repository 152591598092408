import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import { SIZING } from 'shared/config/formatting';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { STYLES } from 'shared/styles/styles';
import { fontSizes } from 'shared/styles/text';
import theme from 'shared/styles/themes';

export const reactModalCss = {
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: zIndex.zOverlay,
  },
  content: {
    overflow: 'visible',
  },
};

export default css({
  '.brkfst-modal': {
    '&__loading-container': {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      position: 'absolute',
      backgroundColor: `${COLORS.WHITE}80`,
    },
    '&__loading-spinner': {
      padding: 0,
    },
    '&__content-wrapper': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      flexDirection: 'column',
      backgroundColor: COLORS.WHITE,
      boxShadow: theme.shadows.SHADOW12,
      zIndex: zIndex.zModal,
      width: '98%',
    },
    '&__content-wrapper--transparent': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    [`&__content-wrapper--${SIZING.SMALL}`]: {
      borderRadius: '4px',
      maxHeight: '98%',
      [mediaquery.md]: {
        ...theme.modalSize[SIZING.SMALL],
        minWidth: '21rem',
      },
    },
    [`&__content-wrapper--${SIZING.MEDIUM}`]: {
      borderRadius: '4px',
      maxHeight: '98%',
      [mediaquery.md]: {
        ...theme.modalSize[SIZING.MEDIUM],
        minWidth: '21rem',
      },
    },
    [`&__content-wrapper--${SIZING.MEDIUM_WIDE}`]: {
      borderRadius: '4px',
      maxHeight: '98%',
      [mediaquery.md]: {
        ...theme.modalSize[SIZING.MEDIUM_WIDE],
        minWidth: '21rem',
      },
    },
    [`&__content-wrapper--${SIZING.LARGE}`]: {
      borderRadius: '4px',
      maxHeight: '98%',
      ...theme.modalSize[SIZING.LARGE],
      top: '46%',
      width: '98%',
      [mediaquery.md]: {
        width: '60%',
        minWidth: '21rem',
      },
    },
    [`&__content-wrapper--${SIZING.FULLSCREEN}`]: {
      ...theme.modalSize[SIZING.FULLSCREEN],
      [mediaquery.md]: {
        minWidth: '21rem',
      },
    },
    [`&__content-wrapper--${SIZING.FULLSCREEN}--with-title`]: {
      backgroundColor: COLORS.NEUTRAL50,
    },
    '&__header': {
      backgroundColor: COLORS.WHITE,
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      height: '3rem',
    },
    '&__header--with-title': {
      borderBottom: STYLES.BORDER_NEUTRAL,
      marginBottom: theme.space[3],
    },
    '&__header--transparent': {
      backgroundColor: 'transparent',
      borderBottom: 'none',
    },
    '&__header-btn': {
      position: 'absolute',
      right: 0,
      margin: 0,
      color: COLORS.NEUTRAL300,
    },
    '&__header-text': {
      alignSelf: 'center',
    },
    [`&__header-text--${SIZING.SMALL}, &__header-text--${SIZING.MEDIUM}, &__header-text--${SIZING.MEDIUM_WIDE}, &__header-text--${SIZING.LARGE}`]:
      {
        margin: `0 ${theme.space[3]}`,
      },
    [`&__header-text--${SIZING.FULLSCREEN}`]: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: fontSizes[5],
      margin: `0 ${theme.space[5]}`,
    },
    '&__component-wrapper': {
      overflowY: 'auto',
      paddingTop: '0.25rem',
      height: '100%',
    },
    '&__component-wrapper form:first-of-type': {
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    '&__footer-wrapper': {
      borderTop: STYLES.BORDER_NEUTRAL,
      flexShrink: 0,
      padding: theme.space[4],
    },
  },
});
