import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.textarea-input--error textarea': {
    border: `1px solid ${COLORS.RED500}`,
  },
  'textarea + .characters-remaining': {
    visibility: 'hidden',
    height: 0,
    margin: 0,
  },
  'textarea:focus + .characters-remaining': {
    visibility: 'initial',
    height: 'auto',
  },
});
