import { useEffect } from 'react';
import { connect } from 'formik';
import { scrollToElement } from './util';
import { getFirstErrorFieldname } from 'shared/utilities/formikUtility';

const ErrorFocus = ({ offset = 0, formik }) => {
  useEffect(() => {
    const { isSubmitting, isValidating, errors } = formik;
    let errorFieldname = getFirstErrorFieldname(errors);

    if (errorFieldname && isSubmitting && !isValidating) {
      // first check if element has special attributes for inputs that can't focused using name attribute (richtext, tag)
      const specialSelector = `[data-focus-selector="${errorFieldname}"]`;
      const specialErrorElement: HTMLElement | null = document.querySelector(specialSelector);

      if (specialErrorElement) {
        specialErrorElement.scrollIntoView();
      } else {
        // select inputs
        const selector = `[name="${errorFieldname}"]`;
        let errorElement: HTMLElement | null = document.querySelector(selector);

        //Check if the error is coming from a field array tab
        if (errorElement === null) {
          //Focus on the tab since the form with the error is not expanded
          const fieldArrayTabErrorSector = '[data-tab-focus-selector="true"]';
          errorElement = document.querySelector(fieldArrayTabErrorSector);
        }

        if (errorElement) {
          scrollToElement(errorElement, offset);

          errorElement.focus();
        }
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors]);

  return null;
};

export default connect(ErrorFocus);
