import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { useUser } from 'features/users/hooks/useUser';
import CreatorAccountSettings from 'features/users/pages/CreatorAccountSettings/CreatorAccountSettings';
import CreatorProfile from 'features/users/pages/CreatorProfile/CreatorProfile';
import CreatorShowcase from 'features/users/pages/CreatorShowcase';

import { RESOURCES } from 'shared/config/resourceNames';
import { noAccess } from 'shared/utilities/routerUtility';
import { CreatorUser } from 'shared/typings/user';

const CreatorUserRoutes = () => {
  const params = useParams<any>();
  const navigate = useNavigate();
  // match.path = 'user/:userId'
  const userId = +params.userId;

  if (!userId) {
    noAccess(navigate, `${RESOURCES.USER} Page`);
  }

  const { user, isCurrentUser, loading, fetchUser, getUserAddress, isUserCreator, displayTags } = useUser(userId);

  useEffect(() => {
    if (isEmpty(user) || !user?.id) fetchUser(userId);
  }, [userId, user]);

  useEffect(() => {
    if (isUserCreator) getUserAddress(userId);
  }, [userId, isUserCreator]);

  if (loading) return <CenteredSpinner />;

  return (
    <Routes>
      {isCurrentUser ? (
        <>
          <Route
            index
            element={
              <CreatorProfile isCurrentUser={isCurrentUser} user={user as CreatorUser} creatorTags={displayTags} />
            }
          />
          <Route path="settings" element={<CreatorAccountSettings />} />
          <Route path="approved-assets" element={<CreatorShowcase user={user} />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </>
      ) : (
        <Route
          path="*"
          element={
            <Navigate
              to="/no-access"
              state={{
                type: `${RESOURCES.USER} Page`,
              }}
            />
          }
        />
      )}
    </Routes>
  );
};

export default CreatorUserRoutes;
