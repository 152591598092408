import { RichtextValue } from 'shared/components/Richtext/shared/types';

import { ModuleFileType } from 'shared/typings/briefs';
import { BrkfstFile } from 'shared/typings/file';
import { ModuleSubmissionStatus } from 'shared/typings/submissions';

export enum SubmissionAssetFields {
  NAME = 'name',
  // These two aren't used here, but for consistency they're defined here.
  SIZE = 'size',
  METADATA = 'metadata',
}

export enum SubmissionFormFields {
  DESCRIPTION = 'description',
  MODULE_ASSETS = 'moduleAssets',
  PUBLISH = 'publish',
  CANCEL = 'cancel',
}

export enum SubmissionModuleFields {
  FILES = 'files',
  // For internal form use
  MODULE_ASSET_COUNT = 'moduleAssetCount',
  // Touched is being added since I couldn't find a simple way to manage touched properly.
  TOUCHED = 'touched',
  MODULE_ID = 'moduleId',
  SUBMISSION_MODULE_ID = 'id',
  STATUS = 'status',
  MODULE_FILE_TYPE = 'fileType',
  MIN_DURATION = 'minDuration',
  MAX_DURATION = 'maxDuration',
}

export type FormSubmissionModule = {
  files: BrkfstFile[];
  moduleAssetCount: number;
  touched: boolean;
  moduleId: number;
  id?: number;
  status?: ModuleSubmissionStatus;
  fileType: ModuleFileType;
  maxDuration: number;
  minDuration: number;
  moduleNum: number;
  name: string;
};

// This is here because useSubmissions is javascript file without types.
export type SubmitValues = {
  description: string;
  moduleAssets: FormSubmissionModule[];
  updatedModuleIds: number[];
};

export type SubmissionFormState = {
  description: RichtextValue;
  moduleAssets: FormSubmissionModule[];
};
